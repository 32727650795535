import React from 'react';
import { Link } from 'react-router-dom';

interface FeC {
  heading: string;
  imageUrl: string;
  companies: number;
  link: string;
}

const FeaturedCard: React.FC<FeC> = (props: FeC) => {
  return (
    <div className='flex-1 rounded-2xl border border-primary'>
      <Link to={props.link}>
        <div>
          <img
            src={props.imageUrl}
            alt='Location'
            className='h-40 w-full object-cover rounded-2xl'
          />
        </div>
        <div className='text-center font-semibold p-2'>
          <p className='text-secondary w-1/2 mx-auto'>{props.heading}</p>
          <p className='text-sm'>{props.companies}+ Companies</p>
        </div>
      </Link>
    </div>
  );
};

export default FeaturedCard;
