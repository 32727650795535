import { useState } from 'react';
import ActiveBanner from './ActiveBanner';
import InactiveBanner from './InactiveBanner';

const Index = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='my-4'>
      {isOpen ? (
        <ActiveBanner isOpen={isOpen} setIsOpen={setIsOpen} />
      ) : (
        <InactiveBanner isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </div>
  );
};

export default Index;
