import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import WorkExperienceForm from './WorkExperienceForm';

type addWorkProps = {
  currentExperince: any;
  closeModal: Function;
};

const AddWorkExperience = (props: addWorkProps) => {
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Work Experience
        <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling all the fields is mandatory if work experince is more than 6
        Months
      </div>
      <div className='mt-4'>Experience</div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            companyName: '',
            country: '',
            title: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyWorkingHere: false,
          }}
          validationSchema={Yup.object({
            companyName: Yup.string().required('Company Name is required'),
            country: Yup.string().required('Country is required'),
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            startDate: Yup.string().required('Start Date is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const currentExperince = [...props.currentExperince];
            currentExperince.push(values);
            updateUser({ workExperience: currentExperince })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <WorkExperienceForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddWorkExperience;
