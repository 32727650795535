import { Link } from 'react-router-dom';

const exploreJobsInfos = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1674038979/landing/Goa_Option_1_i9sy2r_s6hmln.png',
    heading: 'Work In Goa',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1680519293/landing/Build_3logo_sbcv2r.webp',
    text: 'and 30+ Companies',
    link: '/jobs?city[0]=Goa',
    // link: 'https://belong.education/jobs',
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213666/uploaded_resources/delhi_ggwdfg.png',
    heading: 'Work from Home',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1658212962/uploaded_resources/tcs_fleogr.png',
    text: 'and 150+ Companies',
    link: '/jobs?jobType[0]=Remote',
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213697/uploaded_resources/global_nkojj3.png',
    heading: 'Work Globally',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1658213596/uploaded_resources/wework-labs_pau78o.png',
    text: 'and 70+ Companies',
    link: '/jobs?jobType[0]=Remote',
  },
];

const ExploreJobs = () => {
  return (
    <div className='md:grid md:space-x-4 md:grid-cols-3 lg:px-28'>
      {exploreJobsInfos.map(
        ({ id, image, heading, companyLogo, text, link }) => {
          return (
            <div key={id} className='lg:col-span-1 lg:px-6'>
              <Link to={link}>
                <div className='lg:mb-2 mb-4 rounded-3xl text-center   border-2 lg:pb-6  pb-4 transition ease-in-out hover:scale-110'>
                  <img
                    src={image}
                    alt='opportunity'
                    className='object-cover w-full  h-32 rounded-2xl '
                  />
                  <div className='grid content-around'>
                    <p className='text-secondary lg:font-semibold text-center font-normal lg:text-2xl text-lg mt-2 lg:mt-4'>
                      {heading}
                    </p>
                    <img
                      src={companyLogo}
                      alt='logo-here'
                      className='object-contain w-32 h-14 my-2  mx-auto'
                    />
                    <p className='lg:font-semibold font-medium text-sm lg:text-base text-textColor-main'>
                      {text}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        }
      )}
    </div>
  );
};

export default ExploreJobs;
