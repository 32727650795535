import React, { useEffect, useState } from 'react';
import useAptitudeTestResponsesContext from '../../../../../../hooks/useAptitudeTestResponsesContent';
import QuestionCell from './QuestionCell';
import { FaStopwatch } from 'react-icons/fa';
import { getRemainingTime } from '../../utils/helper';
import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
import { useNavigate } from 'react-router-dom';
import { useFinishAptitudeTestMutation } from '../../AptitudeQuizSlice';

const QuestionGrid: React.FC<{ closeModal?: any }> = ({ closeModal }) => {
  const { state, dispatch } = useAptitudeTestResponsesContext();
  const currentCategoryNo = state.currentCategoryNo;
  const timeAllowed = state.responses[currentCategoryNo].timeAllowed;
  const startTime = state.responses[currentCategoryNo].startTime;
  const remainingTime = getRemainingTime(startTime, timeAllowed);
  const [timeLeft, setTimeLeft] = useState(remainingTime);

  const [finishAptitudeTest] = useFinishAptitudeTestMutation();
  const navigate = useNavigate();

  function finishTest() {
    finishAptitudeTest({})
      .unwrap()
      .then((res: any) => {
        navigate('/belong-score/aptitude/scorecard');
      });
  }
  //Update time left when remaining time changes with category
  useEffect(() => {
    setTimeLeft(remainingTime);
  }, [remainingTime, state.currentCategoryNo]);

  //Timeout for category
  useTimeout(() => {
    if (state.currentCategoryNo < 3) {
      dispatch({
        type: 'UPDATE_CATEGORY',
      });
    }
    if (state.currentCategoryNo === 3 && timeLeft === 0) {
      finishTest();
    }
  }, timeLeft * 1000);

  //Timer for category
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;

    dispatch({
      type: 'UPDATE_REMAINING_CATEGORY_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return (
    <div className='p-4'>
      <div className='flex items-center justify-between mb-4'>
        <div className='text-gray-500 lg:font-semibold text-xs lg:text-md '>
          {currentCategoryNo + 1}/4{' '}
          {state.responses[currentCategoryNo].category}
        </div>
        <div>
          <div className='flex  shadow-lg p-2 rounded-xl'>
            <FaStopwatch className='mt-1 text-primary' />
            <p className='ml-1 text-gray-600 text-base'>
              : {state.categoryTimeLeft.minutes} :
              {state.categoryTimeLeft.seconds}
            </p>
          </div>
        </div>
      </div>

      <div className='grid lg:grid-cols-5 grid-cols-4  gap-2  md:gap-4'>
        {state.responses[currentCategoryNo].questions.map(
          (question: any, index: number) => {
            return (
              <QuestionCell
                num={index + 1}
                question={question}
                key={question._id}
                closeModal={closeModal}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default QuestionGrid;
