import React from 'react';

const NotFound = () => {
  return (
    <div className='h-screen grid place-items-center'>
      <p>404 | Not Found</p>
    </div>
  );
};

export default NotFound;
