import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../../../layout/FormElement';
import { Auth } from 'aws-amplify';

type passProps = {
  closeModal: Function;
};

const ChangePassword = (props: passProps) => {
  async function handleChangeClick(oldPassword: string, password: string) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, password);
      props.closeModal();
    } catch (error) {}
  }

  return (
    <div className='px-8'>
      <div className='flex justify-between sm:w-80 text-primary pl-2'>
        Change Your Password
        <button onClick={() => props.closeModal(false)}>X</button>
      </div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            oldPassword: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required('Old Password is required'),
            password: Yup.string()
              .required('Please enter your Password')
              .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                'Password must contain at least 8 characters, one uppercase, one number and one special case character'
              ),
            confirmPassword: Yup.string()
              .required('Please confirm your Password')
              .when('password', {
                is: (password: any) => password && password.length,
                then: Yup.string().oneOf(
                  [Yup.ref('password')],
                  "Password doesn't match"
                ),
              }),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleChangeClick(values.oldPassword, values.confirmPassword);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl  lg:h-80 sm:w-80 text-center'>
              <div>
                <TextInput
                  name='oldPassword'
                  type='password'
                  placeholder='Old Password'
                />
              </div>
              <div>
                <TextInput
                  name='password'
                  type='password'
                  placeholder='New Password'
                />
              </div>
              <div>
                <TextInput
                  name='confirmPassword'
                  type='password'
                  placeholder='Confirm New Password'
                />
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-2 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update Password'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
