import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Select } from '../../layout/FormElement';
import { axiosApi } from 'config/Axios';
import { useQuery } from '@tanstack/react-query';
import { useUpdateUserMutation } from '../userSlice';

const fetchIndustry = async () => {
  const res = await axiosApi.get('/industries', {
    params: {
      limit: 0,
    },
  });
  return res;
};

type editIndustryProps = {
  industryId: any;
  closeModal: Function;
};

const EditIndustry = (props: editIndustryProps) => {
  const { status, data } = useQuery(['fetchIndustry'], fetchIndustry);
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Industry
        <button onClick={() => props.closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling this field is mandatory
      </div>
      <div className='mt-4 text-center mx-2'>
        Choose the Current Industry that you work in or the industry you aspire
        to work in
      </div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            industryId: props.industryId ? props.industryId._id : '',
          }}
          validationSchema={Yup.object({
            industryId: Yup.string().required('Please Select a industry'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const industryObj = data?.data.data.filter((ind: any) => {
              return ind._id === values.industryId;
            })[0];
            if (industryObj) {
              updateUser({
                industryId: { _id: industryObj._id, title: industryObj.title },
              })
                .unwrap()
                .then((res) => closeModal());
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <div className='text-center bg-gray-50 p-8'>
                {status === 'success' && (
                  <Select
                    name='industryId'
                    type='text'
                    placeholder='Choose Industry'
                  >
                    <option value=''> Choose Industry</option>
                    {data.data.data.map((ind: any) => {
                      return (
                        <option key={ind._id} value={ind._id}>
                          {ind.title}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditIndustry;
