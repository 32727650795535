import { Link } from 'react-router-dom';
import AboutInfo from './AboutInfo';
import TransitionProgress from './TransitionProgress';

const BelongScore = () => {
  return (
    <>
      <div className='col-span-12 text-center mt-24 lg:mt-32'>
        <h1 className='text-textColor-main font-semibold lg:text-3xl text-2xl'>
          What is <span className='text-primary'> Belong Score?</span>
        </h1>
        <p className='lg:text-xl text-lg  text-textColor-lighter my-4 lg:my-10'>
          Industry accepted Employability Assessment to capture your skills,
          knowledge and experience
        </p>
      </div>
      <div className='hidden md:block'>
        <TransitionProgress steps={3} direction='row' />
      </div>

      <div className='col-span-12  mt-10'>
        <AboutInfo />
      </div>
      <div className='col-span-12 text-center'>
        <h1
          className='text-textColor-main  font-semibold
          lg:text-3xl text-2xl  mt-8 lg:mt-4'
        >
          Build Your Employability Proof with
          <span className='text-primary'> Belong Score </span>
        </h1>
        <div className='mb-8 mt-6 lg:mt-12'>
          <Link
            to='/login'
            className='bg-primary text-white  px-12 py-2 rounded-lg   font-bold  lg:text-xl shadow '
          >
            Try For Free
          </Link>
        </div>
      </div>
    </>
  );
};

export default BelongScore;
