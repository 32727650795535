import { Link } from 'react-router-dom';

const MapPart2 = () => {
  return (
    <div>
      <div className='grid grid-col-12 text-center bg-primary-lightest lg:-mx-20 py-12 '>
        <p className='text-textColor-main lg:font-semibold font-medium lg:text-3xl text-xl'>
          Find Global Opportunities with
          <span className='text-primary'> Belong Score </span>
        </p>
        <div className='mt-12 mx-auto w-full'>
          <Link
            to='/login'
            className='bg-primary text-white px-12 py-2 rounded-lg  font-bold  lg:text-xl shadow'
          >
            Try For Free
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MapPart2;
