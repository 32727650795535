import HowTo from './HowTo';

const infos = [
  {
    id: 1,
    heading: 'Sign up and Create Your Profile',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1680589654/landing/first_qve5q7.svg',
    activeImg:
      'https://res.cloudinary.com/belong/image/upload/v1680590076/landing/one_z6wfgi.svg',
  },
  {
    id: 2,
    heading: ' Build your skill proof with Capstones',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1680589654/landing/second_to9ucn.svg',
    activeImg:
      'https://res.cloudinary.com/belong/image/upload/v1680590076/landing/two_wbtpm2.svg',
  },
  {
    id: 3,
    heading: 'Get Your Belong Score',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1680589655/landing/third_rgpc4b.svg',
    activeImg:
      'https://res.cloudinary.com/belong/image/upload/v1680590077/landing/three_dafwew.svg',
  },
  {
    id: 4,
    heading: 'Secure Internships & Jobs',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1680589654/landing/fourth_ycaxdz.svg',
    activeImg:
      'https://res.cloudinary.com/belong/image/upload/v1680590076/landing/four_kbf86e.svg',
  },
];

const HowToInfo = () => {
  return (
    <div className='space-y-8 '>
      {infos.map(({ id, heading, image, activeImg }) => {
        return (
          <HowTo
            key={id}
            heading={heading}
            image={image}
            activeImg={activeImg}
          />
        );
      })}
    </div>
  );
};

export default HowToInfo;
