import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomLeftArrow from './CustomLeftArrow';
import CustomRightArrow from './CustomRightArrow';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const scoreImageData = [
  {
    num: 1,
    scorecard:
      'https://res.cloudinary.com/belong/image/upload/v1661158298/landing/Group_294_1_mgac3e.png',
  },
  {
    num: 2,
    scorecard:
      'https://res.cloudinary.com/belong/image/upload/v1661776486/landing/Group_349_1_tibgrp.png',
  },
  {
    num: 3,
    scorecard:
      'https://res.cloudinary.com/belong/image/upload/v1661158303/landing/Group_296_1_g1v4md.png',
  },
];
const SliderScoreImages = ({ deviceType }) => {
  return (
    <Carousel
      arrows={true}
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      //   customTransition='all .5'
      //   transitionDuration={500}
      containerClass='carousel-container'
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={deviceType}
      //   dotListClass='custom-dot-list-style'
      // itemClass='carousel-item-padding-0-px'
    >
      {scoreImageData.map(({ num, scorecard }) => {
        return (
          <div key={num} className='w-72 md:w-full mx-auto md:h-full'>
            <img
              src={scorecard}
              alt='candidate-scorecard '
              className='md:h-4/5 mx-auto '
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default SliderScoreImages;
