import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import ProjectForm from './ProjectForm';

type addWorkProps = {
  currentProject: any;
  closeModal: Function;
};

const AddProject = (props: addWorkProps) => {
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Portfolio
        <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling this field will make you stand out and enhance your chance to
        get interviews
      </div>
      <div className='mt-4'>Projects</div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            projectName: '',
            country: '',
            projectUrl: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyWorking: false,
          }}
          validationSchema={Yup.object({
            projectName: Yup.string().required('Project Name is required'),
            description: Yup.string().required(
              'Project description is required'
            ),
            startDate: Yup.string().required('Project start date is required'),
            // endDate: Yup.string().when('currentlyWorking', {
            //   is: false,
            //   then: Yup.string().required('Project end date is required.'),
            // }),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const currentProject = [...props.currentProject];
            currentProject.push(values);
            updateUser({ projectPortfolio: currentProject })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <ProjectForm
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddProject;
