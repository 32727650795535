import React from 'react';
import FaqContent from './FaqContent';

const Answer: React.FC<{ content: any }> = ({ content }) => {
  return (
    <div className='space-y-6 pt-8'>
      {content.map((content: any, index: number) => (
        <FaqContent content={content} key={index} />
      ))}
    </div>
  );
};

export default Answer;
