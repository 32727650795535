import React from 'react';
import {
  Checkbox,
  TextInput,
  DatePickerField,
} from '../../../layout/FormElement';

type workFormProps = {
  values: any;
  setFieldValue: Function;
  errors: any;
};

const WorkExperienceForm = (props: workFormProps) => {
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  return (
    <div className='grid grid-cols-2 gap-8 p-4 sm:p-8 bg-gray-50'>
      <div>
        <TextInput name='companyName' type='text' placeholder='Company Name' />
      </div>
      <div>
        <TextInput name='country' type='text' placeholder='Country' />
      </div>
      <div className='col-span-2'>
        <TextInput name='title' type='text' placeholder='Title' />
      </div>
      <div className='col-span-2'>
        <TextInput name='description' type='text' placeholder='Description' />
      </div>
      <div>
        <DatePickerField
          label='Start Date'
          name='startDate'
          value={values.startDate}
          onChange={setFieldValue}
          maxDate={new Date()} //new
        />
        <div className='text-sm text-red-500'>{props.errors.startDate}</div>
      </div>
      <div>
        <DatePickerField
          label='End Date'
          name='endDate'
          value={values.endDate}
          onChange={setFieldValue}
          minDate={values.startDate ? new Date(values.startDate) : null} //new
          disabled={values.currentlyWorkingHere}
        />
        <div className='text-sm text-red-500'>{props.errors.endDate}</div>
      </div>
      <div className='col-span-2'>
        <Checkbox name='currentlyWorkingHere'>
          I am currently working in this role
        </Checkbox>
      </div>
    </div>
  );
};

export default WorkExperienceForm;
