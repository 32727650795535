import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLogout } from 'react-icons/ai';
import { ProfileWidget } from 'components/global/layout/ProfileWidget';
import { AiOutlineMenu } from 'react-icons/ai';
interface props {
  showSidebar: boolean;
  setshowSidebar: Function;
}

const MobileNavBar: React.FC<props> = ({ showSidebar, setshowSidebar }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='flex justify-between items-center mb-4 md:mb-8'>
        <div>
          <Link to='/'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
              alt='Belong'
              className='hidden md:block w-48'
            />
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
              alt='Belong'
              className='md:hidden w-16'
            />
          </Link>
        </div>
        <div className='flex items-center gap-4'>
          <AiOutlineLogout
            onClick={async () => {
              await Auth.signOut({ global: true });
              navigate('/login', { replace: true });
            }}
            className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
          />
          <ProfileWidget />
          <button
            className='lg:hidden'
            onClick={() => setshowSidebar(!showSidebar)}
          >
            <AiOutlineMenu className='inline text-2xl' />
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileNavBar;
