import CountUp from 'react-countup';

const achievements = [
  { id: '1', number: 3000, symbol: '+', text: 'Learners Placed' },
  { id: '2', number: 750, symbol: '+', text: 'Employers Hiring' },
  { id: '3', number: 120, symbol: '+', text: 'University Partners' },
  { id: '4', number: 30, symbol: ' LPA', text: 'Highest package' },
];
const OurAchievements = () => {
  return (
    <div className='grid grid-rows-2 grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 gap-x-4 gap-y-8'>
      {achievements.map(({ id, number, text, symbol }) => {
        return (
          <div key={id}>
            <div className='text-primary font-medium text-2xl lg:text-3xl text-center'>
              <CountUp
                enableScrollSpy={true}
                start={0}
                end={number}
                duration={4}
                suffix={symbol}
              >
                {number}
              </CountUp>
            </div>
            <p className='lg:text-xl text-textColor-lightest lg:mt-2 text-center'>
              {text}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default OurAchievements;
