import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaPlus } from 'react-icons/fa';
import { customStyles } from './ModalStyles';
// import EditDomain from './editDetails/EditDomain';
import NoDomain from './NoDomain';

type domainProps = {
  areas: any;
};

const Domain = (props: domainProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        Domain
        <div>
          <button onClick={() => openModal()} className='text-lg text-primary'>
            <FaPlus className='mr-4' />
          </button>
        </div>
      </div>
      <div>
        {props.areas.length ? (
          props.areas.map((area: any) => {
            return <div key={area._id}>{area.title}</div>;
          })
        ) : (
          <div></div>
          // <NoDomain />
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <NoDomain />
        </div>
      </Modal>
    </div>
  );
};

export default Domain;
