import { NavLink } from 'react-router-dom';
import sidebarConfig from '../../config/sidebarConfig.json';
import classNames from 'classnames';
import { FaLock } from 'react-icons/fa';

interface props {
  showSidebar: boolean;
  setshowSidebar: Function;
}

const Sidebar: React.FC<props> = ({ showSidebar, setshowSidebar }) => {
  return (
    <>
      <nav
        className={classNames('space-y-2', {
          'hidden md:block': !showSidebar,
        })}
      >
        {sidebarConfig.map((element) => {
          return (
            <div key={element.path}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'bg-primary-lightest rounded-full w-full inline-block text-gray-500 px-6 py-4'
                    : element.isDisabled
                    ? 'rounded-full w-full inline-block px-6 py-4 pointer-events-none'
                    : 'hover:bg-primary-lightest transition-all ease-in-out duration-300 text-gray-500 rounded-full w-full inline-block px-6 py-4'
                }
                to={element.path}
                onClick={() => setshowSidebar(false)}
              >
                <div className='flex justify-between'>
                  <div className='flex items-center space-x-4 '>
                    <img
                      className='object-contain'
                      src={element.icon}
                      alt='icons'
                    />
                    <span className='font-semibold text-textColor-main/60'>
                      {element.text}
                    </span>
                  </div>

                  {element.isDisabled && <FaLock className='text-gray-400' />}
                </div>
              </NavLink>
            </div>
          );
        })}
      </nav>
    </>
  );
};

export default Sidebar;
