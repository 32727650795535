import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextArea } from '../../layout/FormElement';

import { useUpdateUserMutation } from '../userSlice';

type aboutProps = {
  about: String;
  closeModal: Function;
};

const EditAbout = (props: aboutProps) => {
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        About
        <button onClick={() => closeModal(false)}>X</button>
      </div>
      <div className='text-xs'>
        Filling this field will make you stand out and enhance your chance to
        get interviews
      </div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            about: props.about,
          }}
          validationSchema={Yup.object({
            about: Yup.string().required('About is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateUser(values)
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <div className='bg-gray-50 p-8'>
                <TextArea name='about' type='text' placeholder='About' />
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditAbout;
