import { IoMdArrowDropdown } from 'react-icons/io';
import ActiveBanner from './ActiveBanner';
const InactiveBanner = ({ isOpen, setIsOpen }) => {
  return (
    <>
      {isOpen ? (
        <ActiveBanner />
      ) : (
        <>
          <div className='relative space-y-4 lg:pl-16 justify-between lg:space-y-0  pt-12 pb-6 md:py-6  -mx-8 md:-mx-20 flex flex-col lg:flex-row  bg-gradient-to-br from-[#E4EEFF] to-[#FFFCEC]'>
            <div className='text-center  px-4 '>
              <p className='text-textColor-main font-semibold lg:text-4xl text-3xl  '>
                <span className='text-primary'> Labels </span>
                Are For Clothes, Not
                <span className='text-primary'> People </span>
              </p>
            </div>
            {/* button and dropdown arrow */}
            <div className='flex items-center mx-auto'>
              <div className='text-center'>
                <a
                  href='https://mycoding.belong.education'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <button className='w-fit mx-auto bg-gradient-to-r from-[#10ABB7] to-[#813588] text-white font-semibold py-2 px-4 lg:px-8 rounded'>
                    Register For Bootcamp
                  </button>
                </a>
              </div>
            </div>
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className='absolute cursor-pointer -top-2 right-[5%] md:top-0 md:right-[10%] lg:top-1/3 lg:right-[5%] bg-[#CFF4F2] p-1 rounded'
            >
              <IoMdArrowDropdown className='h-6 w-6 text-black ' />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InactiveBanner;
