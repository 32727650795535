import FaqTypes from './FaqTypes';
import { FAQ } from './allFaq';
const Faq = () => {
  return (
    <>
      <div className='grid grid-cols-12 place-content-center mt-24 lg:mt-32 bg-gray-50 -mx-4 p-4 md:p-0 md:bg-white'>
        <div className='col-span-12 '>
          <h1 className='text-textColor-main lg:font-semibold font-medium lg:text-3xl text-2xl text-center'>
            Frequently Asked Questions
          </h1>
        </div>
        <div className='col-span-12 px-auto lg:mt-10 mt-6 '>
          <div className='lg:w-3/4 mx-auto w-full'>
            {FAQ.map((faq, index) => {
              return (
                <FaqTypes
                  key={index}
                  question={faq.question}
                  content={faq.content}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

// lg: col - span - 8;
export default Faq;
