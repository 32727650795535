import Routes from 'routes';
import './App.css';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
