import React from 'react';
import Chart from 'react-apexcharts';

const BelongScoreChart = (props) => {
  const score = props.score;
  const showScore = isNaN(score) ? 0 : score;

  const series = [33, 33, 33];
  const options = {
    colors: ['#C2FFFB', '#FEE3EA', '#A1C0FD'],
    chart: {
      type: 'donut',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              label: `${showScore}`,
              fontSize: '24px',
              color: '#10ADB9',
              showAlways: true,
              show: false,
              formatter: function () {
                return '';
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  return (
    <div>
      <Chart options={options} series={series} type='donut' />
    </div>
  );
};
export default BelongScoreChart;
