import { createContext, useContext } from 'react';

//TO DO: Find less hacky solution
import { initialState } from 'components/belong-score/quiz/assessment/utils/types';

const domainResponsesInitialState: initialState = {
  domain: '',
  minutesLeft: 0,
  secondsLeft: 0,
  currentQuestion: 0,
  responses: {},
  totalQuestions: 0,
  allSkills: '',
};
const DomainTestResponsesContextObject: {
  state: initialState;
  dispatch: Function;
} = {
  state: domainResponsesInitialState,
  dispatch: () => {},
};

export const DomainTestResponsesContext = createContext(
  DomainTestResponsesContextObject
);

const useDomainTestResponsesContext = () => {
  return useContext(DomainTestResponsesContext);
};

export default useDomainTestResponsesContext;
