import React from 'react';
import About from './About';
import Industry from './Industry';
import Domain from './Domain';
import WorkExperience from './WorkExperience';
import PortfolioProjects from './PortfolioProjects';
import ContactDetails from './ContactDetails';
import Education from './Education';

type fullDetailProps = {
  user: any;
};

const FullDetails = (props: fullDetailProps) => {
  const user = props.user;
  return (
    <div>
      <ContactDetails
        country={user.country}
        email={user.email}
        contactNumber={user.contactNumber}
        linkedInUrl={user.linkedInUrl}
        firstName={user.firstName}
        lastName={user.lastName}
        latestCertification={user.latestCertification}
        currentRole={user.currentRole}
        phoneVerification={user.verification && user.verification.phone}
      />
      <About about={user.about} />
      <Industry industryId={user.industryId} />
      <Domain areas={user.areas || []} />
      <Education educationDetail={user.education || []} />
      <WorkExperience workExp={user.workExperience || []} />
      <PortfolioProjects protfolios={user.projectPortfolio || []} />
    </div>
  );
};

export default FullDetails;
