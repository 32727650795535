import React from 'react';
import { Link } from 'react-router-dom';
import BelongScoreChart from './BelongScoreChart';
import CountUp from 'react-countup';

const BelongScoreCard = (props: any) => {
  const score = isNaN(props.score) ? '' : props.score;
  return (
    <div>
      <p className='text-center mb-2'>MY BELONG SCORE</p>
      <div className='flex justify-center relative'>
        <BelongScoreChart score={score} />
        <p className='text-primary text-3xl font-semibold absolute top-1/2 left-1/2 -translate-x-1/2	-translate-y-1/2	'>
          <CountUp
            enableScrollSpy={true}
            start={0}
            end={score}
            duration={2}
          ></CountUp>
        </p>
      </div>
      <div className='mt-1 text-center relative'>
        <Link
          className='bg-primary-lightest px-8 py-2 rounded-full'
          to={props.url}
        >
          {props.incompleteTest ? 'Resume Test' : 'Start Now'}
        </Link>
      </div>
    </div>
  );
};

export default BelongScoreCard;
