import React, { useState } from 'react';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';
import IndividualProject from './IndividualProject';
import AddProject from './editDetails/projects/AddProject';
import Modal from 'react-modal';
import { customStyles } from './ModalStyles';

type projectProps = {
  protfolios: any;
};

const PortfoliioProjects = (props: projectProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const protfolios = [...props.protfolios];
  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        Portfolio Projects
        <div className='text-lg text-primary'>
          <div className='flex'>
            <button onClick={() => openModal()}>
              <FaPlus className='mr-4' />
            </button>
            {protfolios.length > 0 && (
              <button onClick={() => setShowEdit(!showEdit)}>
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
      </div>
      {protfolios.map((portfolio: any, index: number) => {
        return (
          <IndividualProject
            key={index}
            showEdit={showEdit}
            portfolio={portfolio}
            index={index}
            allProjects={protfolios}
          />
        );
      })}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <AddProject
            closeModal={closeModal}
            currentProject={props.protfolios}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PortfoliioProjects;
