import { createContext, useContext } from 'react';
import { initialState } from 'components/belong-score/quiz/aptitude/utils/types';
//TO DO: Find less hacky solution

const aptitudeResponsesInitialState: initialState = {
  overallTimeLeft: { minutes: 0, seconds: 0 },
  categoryTimeLeft: { minutes: 0, seconds: 0 },
  currentQuestion: 0,
  responses: {},
  totalQuestions: 0,
  currentCategoryNo: 0,
};
const AptitudeTestResponsesContextObject: {
  state: initialState;
  dispatch: Function;
} = {
  state: aptitudeResponsesInitialState,
  dispatch: () => {},
};

export const AptitudeTestResponsesContext = createContext(
  AptitudeTestResponsesContextObject
);

const useAptitudeTestResponsesContext = () => {
  return useContext(AptitudeTestResponsesContext);
};

export default useAptitudeTestResponsesContext;
