import classNames from 'classnames';
import React from 'react';
import useAptitudeTestResponsesContext from '../../../../../../hooks/useAptitudeTestResponsesContent';
import { useFinishAptitudeTestMutation } from '../../AptitudeQuizSlice';
import { useNavigate } from 'react-router-dom';

const ShowQuestion: React.FC = () => {
  const { state, dispatch } = useAptitudeTestResponsesContext();
  const questionToShow =
    state.responses[state.currentCategoryNo].questions[state.currentQuestion];
  const [finishAptitudeTest] = useFinishAptitudeTestMutation();
  const navigate = useNavigate();

  function finishTest() {
    finishAptitudeTest({})
      .unwrap()
      .then((res: any) => {
        navigate('/belong-score/aptitude/scorecard');
      });
  }
  return (
    <div>
      <p className='font-semibold p-4'>
        <span className='text-secondary'>{`${
          state.currentQuestion + 1
        }. `}</span>
        {`${questionToShow.question}`}
      </p>
      <div className='grid grid-flow-col auto-cols-fr gap-4'>
        {questionToShow.image.map((imageName: any) => {
          return (
            <img
              key={imageName}
              className='rounded shadow h-full'
              src={imageName}
              alt='Question'
            />
          );
        })}
      </div>
      {questionToShow.responseType === 'text' ? (
        <input
          type='text'
          placeholder='Your Answer'
          className='p-2 m-2 rounded w-full md:w-3/4'
          value={questionToShow.userInput}
          onChange={(e) =>
            dispatch({
              type: 'UPDATE_INPUT',
              payload: { value: e.target.value },
            })
          }
        />
      ) : (
        questionToShow.options.map((option: any) => {
          return (
            <div
              onClick={() => {
                dispatch({
                  type: 'UPDATE_OPTIONS',
                  payload: {
                    option: option._id,
                    value: !option.selected,
                    maxSelect: questionToShow.maxSelect,
                  },
                });
              }}
              className={classNames(
                'm-4 rounded py-2 px-4',
                {
                  'bg-secondary': option.selected,
                },
                {
                  'bg-gray-100': !option.selected,
                }
              )}
              key={option._id}
            >
              {option.option}
            </div>
          );
        })
      )}
      <div className='flex justify-between mx-4 mt-6 text-primary font-semibold'>
        <div>
          <button
            onClick={() => {
              dispatch({
                type: 'UPDATE_CURRENT_QUESTION',
                payload: state.currentQuestion - 1,
              });
            }}
            className={classNames('text-secondary font-semibold', {
              hidden: !state.currentQuestion,
            })}
          >
            Previous
          </button>
        </div>

        <button
          onClick={() => {
            dispatch({
              type: 'UPDATE_CURRENT_QUESTION',
              payload: state.currentQuestion + 1,
            });
          }}
          className={classNames(
            'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
            { hidden: state.currentQuestion + 1 === state.totalQuestions }
          )}
        >
          Next
        </button>

        {state.currentQuestion + 1 === state.totalQuestions && (
          <button
            onClick={() => {
              dispatch({
                type: 'UPDATE_CATEGORY',
                //payload: state.currentQuestion + 1,
              });
            }}
            className={classNames(
              'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
              { hidden: state.currentCategoryNo === 3 }
            )}
          >
            Next Section
          </button>
        )}

        {state.currentCategoryNo === 3 &&
          state.currentQuestion + 1 === state.totalQuestions && (
            <button
              onClick={() => finishTest()}
              className={classNames(
                'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white'
              )}
            >
              Finish Test
            </button>
          )}
      </div>
    </div>
  );
};

export default ShowQuestion;
