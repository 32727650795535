import { differenceInCalendarDays } from 'date-fns';

function checkAptitudePassOrFail(percentageScore: number) {
  return percentageScore >= 62.5;
}
export function cutOffDateCleared(lastAttemptDate: string) {
  return differenceInCalendarDays(new Date(), new Date(lastAttemptDate)) >= 15;
}

export function getAptitudeUrl(data: any) {
  if (data?.lastAptitudeTestIncomplete) {
    return '/belong-score/aptitude/aptitude-quiz';
  }
  const aptitudeData = data?.aptitude;
  const testCleared = checkAptitudePassOrFail(
    aptitudeData?.bestAttempt?.percentage
  );
  //attempt 2: PASS: Send to domain, FAIL: Send to aptitide score
  if (aptitudeData?.totalAttempts === 2) {
    return '/belong-score/aptitude/scorecard';
  }
  //attempt 0 : Send to aptitude
  if (!aptitudeData?.totalAttempts || data?.lastAptitudeTestIncomplete) {
    return '/belong-score/aptitude';
  }
  //attempt 1: PASS: Send to aptitude score, FAIL: Check for cutoff date: TRUE: Send to aptitude FALSE: Send to aptitude score
  if (testCleared) {
    return '/belong-score/aptitude/scorecard';
  } else {
    if (cutOffDateCleared(aptitudeData.bestAttempt.startTime)) {
      return '/belong-score/aptitude';
    } else {
      return '/belong-score/aptitude/scorecard';
    }
  }
}

export function getDoaminUrl(data: any) {
  const aptitudeData = data?.aptitude;
  //0: Aptitude given at least once and passed: send to domain ELSE send to aptitude
  //1: Handle on select domain screen if test for a domain is already given (show scorecard or start new test of another domain)
  // if(aptitudeData?.totalAttempts >= 1 && checkAptitudePassOrFail(aptitudeData.bestAttempt.percentage)){s
  if (aptitudeData?.totalAttempts >= 1 && !data?.lastAptitudeTestIncomplete) {
    return '/belong-score/domain';
  } else {
    return getAptitudeUrl(data);
  }
}

export function checkIfTestInProgress(data: any) {
  return data?.lastAptitudeTestIncomplete || data?.lastDomainTestIncomplete;
}

export function getAptitudeScore(percentageScore: number) {
  //Score needed to qualify is 25/40 or 62.5%
  //To get weighted percentage
  const MULTIPLE = 0.4;
  const weightedScore = MULTIPLE * percentageScore;
  return { pass: weightedScore >= 25, score: weightedScore || 0 };
}

export function getDomainScore(percentageScore: number) {
  //Score needed to qualify is 35/50 or 70%
  //To get weighted percentage
  const MULTIPLE = 0.5;
  const weightedScore = MULTIPLE * percentageScore;

  return { pass: weightedScore >= 35, score: weightedScore || 0 };
}
