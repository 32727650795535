import React, { Component } from 'react';
import Chart from 'react-apexcharts';
class DomainChart extends Component<
  { score: number; title: string },
  { series: number[]; options: object; dataLabels: object }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      series: [33, 33, 33],
      dataLabels: { enabled: false },
      options: {
        colors: ['#C2FFFB', '#FEE3EA', '#A1C0FD'],
        chart: {
          type: 'donut',
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: '75%',
              labels: {
                show: true,
                total: {
                  label: 'Percentage',
                  fontSize: '14px',
                  showAlways: true,
                  show: true,
                  formatter: function () {
                    return props.score;
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }
  render() {
    return (
      <div id='chart'>
        <p className='text-center mb-2'> {this.props.title} </p>
        <div className='flex justify-center'>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type='donut'
          />
        </div>
      </div>
    );
  }
}

export default DomainChart;
