import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DomainLeftArrow from './DomainLeftArrow';
import DomainRightArrow from './DomainRightArrow';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const domainImagesData = [
  {
    num: 1,
    domainCard:
      'https://res.cloudinary.com/belong/image/upload/v1662364976/landing/Group_351_1_kbcv20.png',
  },
  {
    num: 2,
    domainCard:
      'https://res.cloudinary.com/belong/image/upload/v1662362461/landing/Group_352_dkes6j.png',
  },
  {
    num: 3,
    domainCard:
      'https://res.cloudinary.com/belong/image/upload/v1662364976/landing/Group_352_1_bzsxix.png',
  },
];
const SliderDomainImages = ({ deviceType }) => {
  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      customLeftArrow={<DomainLeftArrow />}
      customRightArrow={<DomainRightArrow />}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      //   customTransition='all .5'
      //   transitionDuration={500}
      containerClass='carousel-container'
      arrows={true}
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={deviceType}
      //   dotListClass='custom-dot-list-style'
      // itemClass='carousel-item-padding-10-px'
    >
      {domainImagesData.map(({ num, domainCard }) => {
        return (
          <div key={num} className='h-full'>
            <img
              src={domainCard}
              alt='candidate-domainCard'
              className='mx-auto'
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default SliderDomainImages;
