import React from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

interface props {
  step: number;
  setStep: Function;
}

const TestStepsBar: React.FC<props> = (props: props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stepScreen = location?.pathname.split('/').pop();

  return (
    <div className='flex justify-center lg:gap-x-28 gap-x-10 lg:mt-12 mt-6 font-bold'>
      <div
        className='lg:w-40 w-20 cursor-pointer'
        onClick={() => {
          navigate('select-domain');
        }}
      >
        <p className='text-center'>STEP 1</p>
        <div className={classNames('h-2 mt-4 bg-secondary')}></div>
      </div>
      <div
        className='lg:w-40 w-20 cursor-pointer'
        onClick={() => {
          navigate('select-skills');
        }}
      >
        <p className='text-center'>STEP 2</p>
        <div
          className={classNames(
            'h-2 mt-4 ',
            {
              'bg-secondary': !(stepScreen === 'select-domain'),
            },
            { 'bg-gray-400': stepScreen === 'select-domain' }
          )}
        ></div>
      </div>
      <div
        className='lg:w-40 w-20 cursor-pointer'
        onClick={() => {
          navigate('start-test');
        }}
      >
        <p className='text-center'>STEP 3</p>
        <div
          className={classNames(
            'h-2 mt-4 ',
            {
              'bg-secondary': stepScreen === 'start-test',
            },
            { 'bg-gray-400': !(stepScreen === 'start-test') }
          )}
          onClick={() => {
            props.setStep(3);
          }}
        ></div>
      </div>
    </div>
  );
};

export default TestStepsBar;
