import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import EditAbout from './editDetails/EditAbout';
import { customStyles } from './ModalStyles';

type aboutProps = {
  about: String;
};

const About = (props: aboutProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        About
        <div onClick={() => openModal()} className='text-lg text-primary'>
          <FaPencilAlt />
        </div>
      </div>

      {props.about && (
        <div className='mt-6'>
          <span className='text-primary text-2xl'>“</span> {props.about}{' '}
          <span className='text-primary text-2xl'>”</span>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditAbout about={props.about} closeModal={closeModal} />
        </div>
      </Modal>
    </div>
  );
};

export default About;
