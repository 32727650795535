import React from 'react';
import classNames from 'classnames';
import { BiUserCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { useGetUserQuery } from 'components/profile/userSlice';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';

export const ProfileWidget: React.FC = () => {
  const { isLoading, data } = useGetUserQuery();
  const profileImage = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1658898215/uploaded_resources/933-9332131_profile-picture-default-png_hmyowh.jpg';
  const notLoggedInContent = <BiUserCircle className='text-5xl text-primary' />;
  const loggedInContent = (
    <div className='flex items-center text-xs md:text-base gap-2 md:gap-4 bg-primary-lightest rounded-full pr-2 md:pr-4'>
      <img
        src={profileImage}
        alt='profile'
        className='h-12 w-12 rounded-full'
      />
      <p className='text-primary font-semibold'>{data?.firstName}</p>
    </div>
  );

  return (
    <Link to='/profile' className={classNames({ hidden: isLoading })}>
      <div className='cursor-pointer'>
        {data ? loggedInContent : notLoggedInContent}
      </div>
    </Link>
  );
};
