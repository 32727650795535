import React from 'react';
import { useGetSummaryQuery } from '../../dashboard/panel/panelSlics';

const ProfileStrengthCard = () => {
  const { data } = useGetSummaryQuery({});
  return (
    <div>
      {data && (
        <div className='bg-gradient-to-b from-[#F6F6F6] to-[#FEE3EA] rounded-lg px-4 mt-4 mx-2 text-center'>
          <p className='pt-4 font-semibold text-lg'>Profile Strength</p>
          <p className='inline-block my-4 px-6 py-2 text-sm font-bold rounded-full bg-white/50 text-primary'>
            {data.profileStrength} /10
          </p>
          <p className='my-2'>
            Complete your profile to improve{' '}
            <span className='text-primary'>
              <button>Belong Score</button>
            </span>
          </p>
          <p className='pb-6 text-xs'>Minimum Score Requirement: 5/10</p>
        </div>
      )}
    </div>
  );
};

export default ProfileStrengthCard;
