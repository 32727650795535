import React from 'react';
import {
  Checkbox,
  TextInput,
  DatePickerField,
} from '../../../layout/FormElement';

type educationFormProps = {
  values: any;
  setFieldValue: Function;
  errors: any;
};

const EducationForm = (props: educationFormProps) => {
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  return (
    <div className='grid grid-cols-2 gap-8 p-4 sm:p-8 bg-gray-50'>
      <div>
        <TextInput
          name='oganizationName'
          type='text'
          placeholder='Oganization Name / School'
        />
      </div>
      <div>
        <TextInput name='degree' type='text' placeholder='Degree' />
      </div>
      <div>
        <TextInput
          name='fieldOfStudy'
          type='text'
          placeholder='field of Study'
        />
      </div>
      <div>
        <TextInput name='grade' type='text' placeholder='Grade' />
      </div>
      <div className='col-span-2'>
        <TextInput
          name='certificateUrl'
          type='text'
          placeholder='Certificate Url'
        />
      </div>
      <div className='col-span-2'>
        <TextInput name='description' type='text' placeholder='Description' />
      </div>
      <div>
        <DatePickerField
          label='Start Date'
          name='startDate'
          value={values.startDate}
          onChange={setFieldValue}
          maxDate={new Date()} //new
        />
        <div className='text-sm text-red-500'>{props.errors.startDate}</div>
      </div>
      <div>
        <DatePickerField
          label='End Date'
          name='endDate'
          value={values.endDate}
          onChange={setFieldValue}
          minDate={values.startDate ? new Date(values.startDate) : null}
        />
        <div className='text-sm text-red-500'>{props.errors.endDate}</div>
      </div>
      <div className='col-span-2'>
        <Checkbox name='currentlyPursuing'>
          I am currently pursuing this degree
        </Checkbox>
      </div>
    </div>
  );
};

export default EducationForm;
