import React, { useState } from 'react';
import { FaCircle, FaPencilAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import Modal from 'react-modal';
import EditWorkExperience from './editDetails/workExperience/EditWorkExperience';
import { customStyles } from './ModalStyles';

type indExpProps = {
  experince: any;
  index: number;
  showEdit: Boolean;
  workExp: any;
};

const IndividualExperience = (props: indExpProps) => {
  const experince = props.experince;
  const endDateText = experince.currentlyWorkingHere
    ? 'Till Now'
    : experince.endDate && format(new Date(experince.endDate), 'MMM u');

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='flex mt-4'>
      <div>
        <FaCircle className='text-primary mr-4 mt-2 text-sm' />
      </div>
      <div className='w-full'>
        <div className='flex justify-between'>
          <div className='font-semibold'>{experince.companyName}</div>
          <div className='flex'>
            {format(new Date(experince.startDate), 'MMM u')} - {endDateText}
            {props.showEdit && (
              <button
                onClick={() => openModal()}
                className='text-lg text-primary ml-3'
              >
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
        <div className='my-1'> {experince.title} </div>
        {experince.description && (
          <ul className='list-disc text-sm'>
            <li>{experince.description}</li>
          </ul>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditWorkExperience
            closeModal={closeModal}
            allExperince={props.workExp}
            index={props.index}
            experince={experince}
          />
        </div>
      </Modal>
    </div>
  );
};

export default IndividualExperience;
