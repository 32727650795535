import SliderEmployers from './SliderEmployers';

const Employers = () => {
  return (
    <div className='bg-[#F4FBFC] -mx-4 md:-mx-20  py-8  mt-24 lg:mt-32 '>
      <div className='col-span-12 text-center '>
        <h1 className='text-textColor-main font-semibold lg:text-3xl text-2xl'>
          <span className='text-secondary'>500+ </span> Employers Trust Belong
        </h1>
      </div>
      <div className='col-span-12 lg:my-8 my-5 '>
        <SliderEmployers />
      </div>
    </div>
  );
};

export default Employers;
