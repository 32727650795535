import React from 'react';
import SkillView from './SkillView';

type skillsProps = {
  data: any;
};

const Skills = (props: skillsProps) => {
  const skillData = props.data;
  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
  ];

  function showImage(percentage: number) {
    let imageToShow = images[0];
    if (percentage < 65) {
      imageToShow = images[2];
    }
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1];
    }
    return imageToShow;
  }

  return (
    <div className='bg-primary-lightest m-2 sm:ml-3 rounded'>
      <div className='text-primary font-semibold text-center'>Skills</div>
      <div className='grid grid-cols-2 gap-2'>
        {skillData.map((skill: any, index: number) => {
          return (
            <SkillView
              key={index}
              index={index + 1}
              skill={skill.skillName}
              image={showImage(skill.percentage)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
