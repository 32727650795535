import { customStyles } from 'components/profile/ModalStyles';
import Modal from 'react-modal';

const CantApplyYet: React.FC<{
  open: boolean;
  setShowApplyBlockedPopup: Function;
  data: any;
}> = ({ open, setShowApplyBlockedPopup, data }) => {
  //Modal should open if canApplyToJob is false

  function closeModal() {
    setShowApplyBlockedPopup(false);
  }
  const text = ['Aptitude Test', 'Domain Test', 'Profile'];

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      data={{ background: 'gray' }}
    >
      <div className='p-2'>
        <div className='text-right mb-4'>
          <button
            onClick={() => {
              setShowApplyBlockedPopup(false);
            }}
          >
            x
          </button>
        </div>
        <div className='flex flex-col'>
          <div>
            <p className='font-bold mb-2'>
              Please complete the following first
            </p>
          </div>
          {data?.map((condition: boolean, index: number) => {
            if (!condition) {
              return <p>{text[index]}</p>;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CantApplyYet;
