const MapLayout = () => {
  return (
    <div
      className='relative h-5/6 bg-contain bg-no-repeat bg-center'
      style={{
        backgroundImage:
          'url(https://res.cloudinary.com/belong/image/upload/v1660282514/landing/map_ja8f7o.png)',
      }}
    >
      <div className='group'>
        <img
          className='absolute lg:top-64 lg:left-1/3 lg:w-12 lg:ml-36 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660117819/landing/Ellipse349_wcr9pb.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-60  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799711/landing/Group_309_xnhrcv.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-44 lg:left-1/3  lg:w-12 lg:ml-72 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118139/landing/Ellipse352_x3wvhz.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-96  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799660/landing/Group_302_yglzoe.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-64 lg:left-1/3  lg:w-12 lg:ml-64 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118219/landing/Ellipse357_i9tjyq.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-80  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799615/landing/Group_304_ht1f3q.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-2/3 lg:left-1/3  lg:w-16 lg:ml-60 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118269/landing/Ellipse350_cwagpr.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-64  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799573/landing/Group_297_gouzjb.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-80 lg:left-1/3  lg:w-14 lg:ml-80 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118320/landing/Ellipse353_fbwqqx.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-96  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799530/landing/Group_301_edkcsi.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-1/3 lg:mt-36 lg:left-1/3  lg:w-14 lg:ml-96 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118368/landing/Ellipse358_jcvcse.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-96  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799483/landing/Group_305_ordagx.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-1/3 lg:mt-44 lg:left-2/3  lg:w-14 lg:ml-24 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118421/landing/Ellipse356_sokc47.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-2/3 lg:ml-32 z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799419/landing/Group_306_e2xx1n.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-1/3 lg:mt-20 lg:left-2/3  lg:w-14 lg:ml-48 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118477/landing/Ellipse355_wvm5dv.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/3 lg:ml-72   z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799368/landing/Group_303_aqouqm.png'
          alt='user'
        />
      </div>

      <div className='group'>
        <img
          className='absolute lg:top-60 lg:left-32 '
          src='https://res.cloudinary.com/belong/image/upload/v1660118533/landing/Ellipse354_i56rn3.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-52  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799278/landing/Group_300_jypttx.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-56 lg:left-1/3 lg:ml-52 lg:w-12 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118589/landing/Ellipse351_a4kkoh.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-1/2 lg:ml-10  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799234/landing/Group_299_lsdhe6.png'
          alt='user'
        />
      </div>
      <div className='group'>
        <img
          className='absolute lg:top-80 lg:left-1/2 lg:ml-56 lg:w-16 object-contain'
          src='https://res.cloudinary.com/belong/image/upload/v1660118647/landing/Ellipse359_asgggt.png'
          alt='user'
        />
        <img
          className='absolute lg:top-0 lg:left-2/3 lg:ml-20  z-10 hidden group-hover:block'
          src='https://res.cloudinary.com/belong/image/upload/v1660799157/landing/Group_298_edikbb.png'
          alt='user'
        />
      </div>
    </div>
  );
};

export default MapLayout;
