import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { customStyles } from './ModalStyles';
import Modal from 'react-modal';
import EditImage from './editDetails/EditImage';
import DeleteImage from './editDetails/DeleteImage';
import { GiBriefcase, GiTiedScroll } from 'react-icons/gi';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../config/API_LINKS';

type basicDetailProps = {
  firstName: String;
  lastName: String;
  latestCertification: String;
  currentRole: String;
  imageUrl: string;
  // GiBriefcase: JSX.Element;
  // GiTiedScroll: JSX.Element;
};

const BasicDetails = (props: basicDetailProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteModalOpen, setDeleteModal] = useState(false);

  function openDeleteModal() {
    setDeleteModal(true);
  }
  function closeDeleteModal() {
    setDeleteModal(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const imageUrl = props.imageUrl;
  const profileImage = imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1660803220/uploaded_resources/am-a-19-year-old-multimedia-artist-student-from-manila--21_bk3itt.png';
  return (
    <div>
      <div className='flex justify-center relative'>
        <img
          className='h-32 w-32 sm:-mt-16 rounded-full object-cover'
          alt='user'
          src={profileImage}
        />
        <div className='absolute left-1/4 md:left-1 lg:left-1/4  mt-16 md:mt-12  lg:mt-8'>
          <button onClick={() => openModal()}>
            <FaPlus className='text-primary text-lg' />
          </button>
        </div>
        <div className='absolute right-1/4  md:right-1 lg:right-1/4 mt-16 md:mt-12 lg:mt-8'>
          {imageUrl && (
            <button onClick={() => openDeleteModal()}>
              <FaTrash className='text-red-600 text-lg ml-2' />
            </button>
          )}
        </div>
      </div>

      <div className='text-center font-bold text-xl mt-4 text-primary'>
        {props.firstName} {props.lastName}
      </div>
      <div className='flex items-center  justify-center font-semibold '>
        <div className=' mr-2 text-lg md:text-xl text-primary'>
          <GiBriefcase />
        </div>
        {props.currentRole}
      </div>
      <div className='flex items-center  justify-center  font-semibold mt-4'>
        <div className=' mr-2 text-lg md:text-xl text-primary'>
          <GiTiedScroll />
        </div>
        {props.latestCertification}
      </div>
      {/* <div className='text-center mt-8'>
        <button className='text-primary text-lg font-semibold border-2 border-[#10ABB7] py-1 px-4 rounded-lg'>
          View Profile
        </button>
      </div> */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditImage closeModal={closeModal} />
        </div>
      </Modal>
      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <DeleteImage closeModal={closeDeleteModal} imageUrl={imageUrl} />
        </div>
      </Modal>
    </div>
  );
};

export default BasicDetails;
