import React, { useState } from 'react';
import { FaCircle, FaPencilAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import Modal from 'react-modal';
import EditEducation from './editDetails/education/EditEducation';
import { customStyles } from './ModalStyles';

type educationProps = {
  education: any;
  index: number;
  showEdit: Boolean;
  allEducationDetail: any;
};

const IndividualEducation = (props: educationProps) => {
  const education = props.education;
  const endDateText = education.currentlyPursuing
    ? 'Till Now'
    : education.endDate && format(new Date(education.endDate), 'MMM u');

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className='flex mt-4'>
      <div>
        <FaCircle className='text-primary mr-4 mt-2 text-sm' />
      </div>
      <div className='w-full'>
        <div className='flex justify-between'>
          <div className='font-semibold'>{education.oganizationName}</div>
          <div className='flex'>
            {format(new Date(education.startDate), 'MMM u')} - {endDateText}
            {props.showEdit && (
              <button
                onClick={() => openModal()}
                className='text-lg text-primary ml-3'
              >
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
        <div className='my-1'>
          {education.degree} , {education.fieldOfStudy} - {education.grade}
        </div>
        {education.description && (
          <ul className='list-disc text-sm'>
            <li>{education.description}</li>
          </ul>
        )}
        <a
          href={education.certificateUrl}
          className='text-xs text-primary break-all'
          target='_blank'
          rel='noreferrer'
        >
          {education.certificateUrl}
        </a>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditEducation
            closeModal={closeModal}
            allEducationDetail={props.allEducationDetail}
            index={props.index}
            education={education}
          />
        </div>
      </Modal>
    </div>
  );
};

export default IndividualEducation;
