import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import EducationForm from './EducationForm';

type addEducationProps = {
  currentEducation: any;
  closeModal: Function;
};

const AddEducation = (props: addEducationProps) => {
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Education
        <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling this field will make you stand out and enhance your chance to
        get interviews
      </div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            oganizationName: '',
            degree: '',
            fieldOfStudy: '',
            grade: '',
            certificateUrl: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyPursuing: false,
          }}
          validationSchema={Yup.object({
            oganizationName: Yup.string().required(
              'Oganization Name is required'
            ),
            degree: Yup.string().required('Degree is required'),
            fieldOfStudy: Yup.string().required('Field Of Study is required'),
            //description: Yup.string().required('Description is required'),
            startDate: Yup.string().required('Start Date is required'),
            endDate: Yup.string().required('End Date is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const currentEducation = [...props.currentEducation];
            currentEducation.push(values);
            updateUser({ education: currentEducation })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <EducationForm
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEducation;
