import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ApplicationStatus = () => {
  return (
    <div className=' flex-1 bg-gradient-to-b from-gray-100 to-primary-lighter shadow-lg rounded-xl'>
      <div className='flex p-4'>
        <div className='grid place-items-center px-8'>
          <AiOutlineLoading3Quarters className='text-5xl font-semibold' />
        </div>
        <div className='text-center flex-1'>
          <Link to='belong-score' className='font-semibold'>
            Application Status
          </Link>
          <p className='text-xs'>
            Apply to Opportunities with your Belong Score
          </p>
        </div>
      </div>

      {/* <div className='flex justify-between items-center shadow rounded-xl mt-4 bg-primary-lightest px-4 py-2'>
        <p className='text-xs w-3/5'>
          Apply to 2 more opportunities and Increase Chance to Get Hired
        </p>
        <p className='font-semibold'>Apply Now</p>
      </div> */}
    </div>
  );
};

export default ApplicationStatus;
