import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLogout } from 'react-icons/ai';
import { ProfileWidget } from 'components/global/layout/ProfileWidget';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className='flex justify-between items-center mb-4 md:mb-8 px-4'>
      <div>
        <Link to='/'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
            alt='Belong'
            className='hidden md:block w-40'
          />
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
            alt='Belong'
            className='md:hidden w-16'
          />
        </Link>
      </div>
      <div className='flex items-center gap-4'>
        <AiOutlineLogout
          onClick={async () => {
            await Auth.signOut({ global: true });
            navigate('/login', { replace: true });
          }}
          className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
        />
        <ProfileWidget />
      </div>
    </div>
  );
};

export default Navbar;
