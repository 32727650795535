import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const inputStyle =
  'shadow p-2 border border-gray-400 focus:border-black rounded mx-2 w-full';
const errorMessageStyle = 'text-sm text-red-500';
const textAreaStyle =
  'shadow p-2 border border-gray-400 focus:border-black rounded mx-2 w-full h-40';

export const TextInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input className={inputStyle} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </>
  );
};

export const TextArea = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea className={textAreaStyle} {...field} {...props}></textarea>
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </>
  );
};

export const Select = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='mx-2'>
      <select
        {...field}
        {...props}
        className='p-2 border border-gray-400 w-full '
      />
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div>
      <label>
        <input className='mx-2' type='checkbox' {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const DatePickerField = ({ name, value, onChange, label, ...props }) => {
  const years = Array.from({ length: 40 }, (_, i) => i + 1990);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <div className='flex'>
      <label htmlFor={props.id || props.name}> {label}</label>
      <DatePicker
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <select
              value={date.getYear() + 1900}
              onChange={(e) => changeYear(e.target.value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={(value && new Date(value)) || null}
        {...props}
        onChange={(val) => {
          onChange(name, val);
        }}
        className='p-2 w-full border border-gray-400 focus:border-black rounded'
      />
    </div>
  );
};
