import React from 'react';
// import PlansSection from './belongPlans/PlansSection';
import Faq from './faqSection/Faq';
import Footer from './Footer';
import Header from './Header';
import JourneySection from './JourneySection';
import DataSection from './page1/DataSection';
import IntroSection from './page1/IntroSection';
import AboutSection from './page2/AboutSection';
import HowToSection from './page3/HowToSection';
import ExploreSection from './page4/ExploreSection';
import MapSection from './page5/MapSection';
import Banner from './banner/index';

// import {useMotionValue} from 'framer-motion';

const Home = () => {
  return (
    <div className=' px-4 md:px-20 font-poppins'>
      <Header />
      <Banner />
      <IntroSection />
      <DataSection />
      <AboutSection />
      <HowToSection />
      <ExploreSection />
      <MapSection />
      {/* <PlansSection /> */}
      <Faq />
      <JourneySection />
      <Footer />
    </div>
  );
};

export default Home;
