import { apiSlice } from '../../../../api/apiSlice';
import { getRemainingTime } from './utils/helper';
import { testSource } from 'config/common';

const source = testSource.belongEducation;

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAptitudeQuiz: builder.query<any, void>({
      query: () => {
        return {
          url: '/question/aptitude/random',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['NewAptitudeQuiz'],
    }),
    checkIncompleteAptitude: builder.query<any, void>({
      query: () => {
        return {
          url: '/test/aptitude-test/incomplete',
          params: { source },
        };
      },
      transformResponse(res: any) {
        const totalTimeForQuizInMinutes = res.data?.responses.reduce(function (
          acc: number,
          category: any
        ) {
          return acc + category.timeAllowed;
        },
        0);

        const startTime = res.data?.startTime;
        const timeLeftForQuiz = getRemainingTime(
          startTime,
          totalTimeForQuizInMinutes
        );
        if (res.data) {
          res.data.responses[0]['startTime'] = res.data?.startTime;
        }
        // const timeLeftPerCategory = res.data.responses.map((cat:any,index:number)=>{
        //   return  {"category":cat.category,"timeLeft": getRemainingTime(cat.startTime || res.data.startTime,cat.timeAllowed)}
        // });
        return { ...res.data, overallTimeLeft: timeLeftForQuiz };
      },
      providesTags: ['CurrentAptitudeQuiz'],
    }),
    aptitudeTestScore: builder.query<any, void>({
      query: () => {
        return {
          url: '/test/aptitude-test/best-attempt',
        };
      },
      transformResponse: (res: any) => {
        return res.data;
      },
    }),
    aptitudeAttempt: builder.query<any, void>({
      query: () => {
        return {
          url: '/test/aptitude-test/attempt-status',
        };
      },
      transformResponse: (res: any) => {
        return res.data;
      },
    }),
    startAptitudeTest: builder.mutation({
      query(data: any) {
        const newData = { ...data, source };
        const { ...body } = newData;
        return {
          url: `/test/aptitude-test/start`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CurrentAptitudeQuiz'],
    }),
    updateResponsesAptitude: builder.mutation({
      query(data: any) {
        const newData = { ...data, source };
        const { ...body } = newData;
        return {
          url: `/test/aptitude-test/update-responses`,
          method: 'POST',
          body,
        };
      },
      // invalidatesTags: ['CurrentAptitudeQuiz'],
    }),
    finishAptitudeTest: builder.mutation({
      query() {
        const newData = { source };
        const { ...body } = newData;
        return {
          url: `/test/aptitude-test/finish`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Summary'],
    }),
  }),
});

export const {
  useGetAptitudeQuizQuery,
  useCheckIncompleteAptitudeQuery,
  useAptitudeTestScoreQuery,
  useAptitudeAttemptQuery,
  useStartAptitudeTestMutation,
  useUpdateResponsesAptitudeMutation,
  useFinishAptitudeTestMutation,
} = extendedApiSlice;
