import React from 'react';

type skillProps = {
  skill: String;
  image: string;
  index: number;
};

const SkillView = (props: skillProps) => {
  const image = props.image;
  return (
    <div className='my-2 flex bg-white col-span-1 sm:col-span-2 rounded-3xl'>
      <div className='bg-primary rounded-full text-center -ml-3 px-3 py-1 text-white'>
        {props.index}
      </div>
      <div className='flex justify-between w-full '>
        <div className='ml-1 text-sm mt-1'>{props.skill} </div>
        <div>
          <img alt='skill' className='mr-2' src={image} />
        </div>
      </div>
    </div>
  );
};

export default SkillView;
