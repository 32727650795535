import React from 'react';
import DomainChart from './DomainChart';
import classNames from 'classnames';
import { useDomainTestScoreQuery } from '../../../belong-score/quiz/QuizSlice';
//import Loading from 'components/global/layout/Loading';

type popUpProps = {
  closeModal: Function;
  setCurrentDomain: Function;
  setSelectedDomainName: Function;
  currentDomain: any;
};

const SelectDomainPopUp = (props: popUpProps) => {
  const { isLoading, data } = useDomainTestScoreQuery();
  const condition = Boolean(data);

  return (
    <>
      {isLoading && (
        <div className='animate-pulse grid grid-cols-1 sm:grid-cols-3 gap-8'>
          <div className='border-2 border-primary-light rounded w-64 h-64'></div>
          <div className='border-2 border-primary-light rounded w-64 h-64'></div>
          <div className='border-2 border-primary-light rounded w-64 h-64'></div>
        </div>
      )}

      {condition && (
        <div className='p-2'>
          <div className='flex justify-between'>
            <div>
              <div className='text-lg text-primary font-semibold'>
                MY BELONG SCORE
              </div>
              <div className='text-xs text-red-600'>
                Select the domain to view domain specific Belong Score on your
                dashboard
              </div>
            </div>
            <div className='ml-2 flex'>
              {/* <button className='border border-gray-400 rounded-lg text-primary font-semibold px-6 py-1 shadow-lg'>
                Apply Now
              </button> */}
              <button
                onClick={() => props.closeModal()}
                className='text-lg text-primary ml-8'
              >
                X
              </button>
            </div>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-8 mt-4'>
            <>
              {Array.from(Array(3).keys()).map((value: any) =>
                data[value] ? (
                  <div
                    onClick={() => {
                      props.setCurrentDomain({
                        areaId: data[value].domain._id,
                      });
                      props.closeModal();
                      props.setSelectedDomainName(data[value].domain.title);
                    }}
                    key={value}
                    className={classNames('border-2 rounded', {
                      'border-primary-light':
                        (!props.currentDomain.areaId && value === 0) ||
                        props.currentDomain.areaId === data[value].domain._id,
                    })}
                  >
                    <DomainChart
                      score={data[value].percentage}
                      title={data[value].domain.title}
                    />
                  </div>
                ) : (
                  <div
                    key={value}
                    className='border-2 border-gray-300 rounded'
                  ></div>
                )
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectDomainPopUp;
