import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

const HowTo = ({ id, heading, image, activeImg }) => {
  let elemRef = useRef(null);
  const [active, setActive] = useState(false);
  useEffect(() => {
    function handleScroll() {
      const topPosition = elemRef.current.getBoundingClientRect().top;
      // const scrollPosition = window.scrollY + window.innerHeight;
      if (topPosition <= 200) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
    const onScroll = () => handleScroll();
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      ref={elemRef}
      key={id}
      className={classNames(
        'flex rounded-lg justify-between p-4 sm:p-6 md:p-8 gap-4',
        { 'bg-primary bg-gradient-to-b from-[#2A3B4E] to-[#10ABB7]': active },
        {
          'bg-light/95 opacity-60 bg-gradient-to-b from-[#10ABB7]/20 to-[#10ABB7]/0 ':
            !active,
        }
      )}
    >
      <div>
        <p
          className={classNames(
            'flex items-center h-full lg:text-xl',
            { 'text-white font-bold ': active },
            {
              'text-black font-medium ': !active,
            }
          )}
        >
          {heading}
        </p>
      </div>
      <div>
        {!active && (
          <img
            className='object-contain  h-8 md:h-12 '
            src={image}
            alt='logo'
          />
        )}
        {active && (
          <img
            className='object-contain  h-8 md:h-12'
            src={activeImg}
            alt='logo'
          />
        )}
      </div>
    </div>
  );
};

export default HowTo;
