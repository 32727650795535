import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import EducationForm from './EducationForm';

type editWorkProps = {
  allEducationDetail: any;
  closeModal: Function;
  index: number;
  education: any;
};

const EditEducation = (props: editWorkProps) => {
  const [loading, setLoading] = useState(false);
  const education = props.education;
  const index = props.index;
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  function removeEducation() {
    setLoading(true);
    const newAllEducation = props.allEducationDetail.filter(
      (_: any, idx: any) => {
        return index !== idx;
      }
    );
    updateUser({ education: newAllEducation })
      .unwrap()
      .then((res) => closeModal());
  }

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Education <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling this field will make you stand out and enhance your chance to
        get interviews
      </div>
      <div className='mt-4'>Projects</div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            oganizationName: education.oganizationName,
            degree: education.degree,
            fieldOfStudy: education.fieldOfStudy,
            grade: education.grade,
            certificateUrl: education.certificateUrl,
            description: education.description,
            startDate: education.startDate,
            endDate: education.endDate,
            currentlyPursuing: education.currentlyPursuing,
          }}
          validationSchema={Yup.object({
            oganizationName: Yup.string().required(
              'Oganization Name is required'
            ),
            degree: Yup.string().required('Degree is required'),
            fieldOfStudy: Yup.string().required('Field Of Study is required'),
            // description: Yup.string().required('Description is required'),
            startDate: Yup.string().required('Start Date is required'),
            endDate: Yup.string().required('End Date is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let allEducationDetail = [...props.allEducationDetail];
            let currenteducation = { ...education };
            currenteducation = { ...currenteducation, ...values };
            allEducationDetail[index] = currenteducation;
            updateUser({ education: allEducationDetail })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <EducationForm
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='flex justify-end text-red-700 mb-4'>
          <button
            disabled={loading}
            className={loading ? 'text-red-300' : ''}
            onClick={() => removeEducation()}
          >
            Remove This Education
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEducation;
