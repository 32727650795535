import React from 'react';
import useDomainTestResponsesContext from '../../../../../hooks/useDomainTestResponsesContent';
import QuestionCell from './QuestionCell';

const QuestionGrid: React.FC<{ closeModal?: any }> = ({ closeModal }) => {
  const { state } = useDomainTestResponsesContext();
  return (
    <div>
      <div className='text-gray-500 font-semibold mb-6'>
        {state.domain + ' - ' + state.allSkills}
      </div>
      <div className='grid lg:grid-cols-5 grid-cols-4  gap-2  md:gap-4'>
        {state.responses.map((question: any, index: number) => {
          return (
            <QuestionCell
              num={index + 1}
              question={question}
              key={question._id}
              closeModal={closeModal}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QuestionGrid;
