import React from 'react';
import Skills from './Skills';
import NoSkills from './NoSkills';
import { useQuery } from '@tanstack/react-query';
import { axiosApi } from '../../../config/Axios';
import ProfileStrengthCard from './ProfileStrengthCard';

const fetchSkillRanking = async () => {
  const res = await axiosApi.get('/user/profile/skills-ranking');
  return res;
};

const ProfileStrength = () => {
  const { status, data } = useQuery(['skillRanking'], fetchSkillRanking);

  return (
    <div>
      <div className='hidden sm:block'>
        <ProfileStrengthCard />
      </div>

      <>
        {status === 'success' && data.data.data.length ? (
          <Skills data={data.data.data} />
        ) : (
          <NoSkills />
        )}
      </>
    </div>
  );
};

export default ProfileStrength;
