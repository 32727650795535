import React, { useState } from 'react';
import { useUpdateUserMutation } from '../userSlice';
import { axiosUpload } from '../../../config/Axios';

type editImageProps = {
  closeModal: Function;
};

const EditImage = (props: editImageProps) => {
  const closeModal = props.closeModal;
  const [updateUser] = useUpdateUserMutation();
  const [errorMessage, setErrorMessage] = useState('');

  async function uploadFile(files: any) {
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const fileExtension = files.name.substring(
        files.name.lastIndexOf('.') + 1
      );
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        updateUser({ imageUrl: result.data.publicUrl })
          .unwrap()
          .then((res) => closeModal());
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }

  return (
    <div>
      <div className='flex justify-between w-full text-primary mb-4'>
        Choose a profile picture:
        <button onClick={() => closeModal()}>X</button>
      </div>
      <input
        type='file'
        accept='image/png, image/jpeg'
        onChange={(e: any) => {
          const file = e.target.files[0];
          uploadFile(file);
        }}
      />

      <div className='text-xs mt-1'>
        Please upload png or jpeg file, Max size: 2 MB
      </div>
      <div className='mt-2 text-red-600'>{errorMessage}</div>
    </div>
  );
};

export default EditImage;
