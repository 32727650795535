import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const IntroSection = () => {
  const [animationState, setAnimationState] = useState('Job');
  const [text, setText] = useState('');

  useEffect(() => {
    const word = animationState;
    let index = 0;
    let currentWordArr = [];
    function animate() {
      if (index < word.length) {
        currentWordArr.push(word[index]);
        setText(currentWordArr.concat());
        index++;
      } else if (index >= word.length && index < word.length * 2) {
        currentWordArr.pop();
        setText(currentWordArr.concat());
        index++;
      } else {
        index = 0;
        currentWordArr = [];
        animationState === 'Job'
          ? setAnimationState('Internship')
          : setAnimationState('Job');
      }
    }

    const int = setInterval(() => {
      animate();
    }, 250);

    return () => {
      clearInterval(int);
    };
  }, [animationState]);

  return (
    <div className='grid grid-cols-12 sm:space-x-6 mt-4 md:mt-0'>
      <div className='grid place-self-center col-span-12 lg:col-span-5 order-2 lg:order-1 space-y-6'>
        <div>
          <p className='text-textColor-main font-bold lg:text-4xl text-3xl lg:leading-relaxed mt-8 lg:mt-0'>
            The New Way To Get
            <br />A Perfect <span className='text-primary'>{text}</span>
            <span className='text-primary font-extralight	'>|</span>
          </p>
          <p className='lg:text-xl text-lg font-normal text-textColor-lighter my-4'>
            Learn with Capstone Projects, crack the Belong Score and Get hired
            by 500+ employers
          </p>
        </div>
        <div>
          <Link
            to='/login'
            className='bg-primary text-white   px-12 py-2 rounded-lg font-bold lg:text-xl  shadow'
          >
            Try For Free
          </Link>
        </div>
      </div>
      <div className='grid justify-items-center lg:justify-items-end items-center col-span-12 lg:col-span-7 order-1 lg:order-2'>
        <div className='sm:h-4/5'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1660284331/landing/Group_292_ixbscx.png'
            alt='sec1Image'
            className='sm:h-full w-auto'
          />
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
