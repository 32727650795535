import { differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface props {
  data: any;
}

const ReAttempt: React.FC<props> = ({ data }) => {
  const dataToProcess = data?.domain.totalAttempts
    ? data?.domain
    : data?.aptitude;

  const remainingDays = differenceInCalendarDays(
    new Date(),
    new Date(dataToProcess?.bestAttempt?.startTime)
  );

  return (
    <div className='flex-1 bg-gradient-to-b from-gray-100 to-primary-lighter shadow-lg rounded-xl'>
      <div className='flex p-4'>
        <div className='grid place-items-center px-8 relative'>
          <AiOutlineLoading3Quarters className='text-7xl font-semibold' />
          <p className='absolute top-1/2 left-1/2 -translate-x-1/2	-translate-y-1/2	'>
            {`${
              isNaN(remainingDays)
                ? 0
                : 15 - remainingDays > 0
                ? 15 - remainingDays
                : 0
            }`}
          </p>
        </div>
        <div className='text-center flex-1'>
          <p className='font-semibold'>Days to Re-Attempt</p>
          <p className='text-xs'>
            Days Left to Unlock the Next Belong Score Re-Attempt
          </p>
        </div>
      </div>

      <div className='hidden flex justify-between items-center shadow rounded-xl mt-4 bg-primary-lightest px-4 py-2'>
        <p className='text-xs w-3/5'>
          Reduce the Days to Re-Attempt with Belong Points
        </p>
        <p className='font-semibold'>Redeem Now</p>
      </div>
    </div>
  );
};

export default ReAttempt;
