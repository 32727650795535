import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput, Select } from '../../layout/FormElement';
import useCountriesData from 'hooks/useCountryData';

import { useUpdateUserMutation } from '../userSlice';

type contactDetailProps = {
  closeModal: Function;
  country: String;
  linkedInUrl: String;
  firstName: String;
  lastName: String;
  latestCertification: String;
  currentRole: String;
};

const EditContactDetails = (props: contactDetailProps) => {
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  const { status, data } = useCountriesData();

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Intro and Contact Details
        <button onClick={() => closeModal(false)}>X</button>
      </div>
      <div className='text-red-600 text-xs'>
        Filling all the fields is mandatory
      </div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: props.firstName,
            lastName: props.lastName,
            linkedInUrl: props.linkedInUrl,
            country: props.country,
            latestCertification: props.latestCertification,
            currentRole: props.currentRole,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            country: Yup.string().required('Country is required'),
            linkedInUrl: Yup.string().url('LinkedIn URL must be a valid URL'),
            latestCertification: Yup.string().required(
              'Latest Certification is required'
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateUser(values)
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <div className='grid grid-cols-2 gap-8 bg-gray-50 p-4 sm:p-8'>
                <div>
                  <TextInput
                    name='firstName'
                    type='text'
                    placeholder='First Name'
                  />
                </div>
                <div>
                  <TextInput
                    name='lastName'
                    type='text'
                    placeholder='Last Name'
                  />
                </div>
                <div>
                  <TextInput
                    name='currentRole'
                    type='text'
                    placeholder='Current Role Title / Student'
                  />
                </div>
                <div>
                  <TextInput
                    name='latestCertification'
                    type='text'
                    placeholder='Latest Degree/ Certification'
                  />
                </div>

                <div>
                  {status === 'success' && (
                    <Select name='country'>
                      <option value=''>Select country</option>
                      {data.map((country: any) => {
                        return (
                          <option key={country.name} value={country.name}>
                            {country.name}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                </div>

                <div>
                  <TextInput
                    name='linkedInUrl'
                    type='text'
                    placeholder='Linkedin Url'
                  />
                </div>
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditContactDetails;
