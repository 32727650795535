import PhonePage1 from './PhonePage1';
import { useState } from 'react';
import PhonePage2 from './PhonePage2';

const Index = () => {
  const [otpSend, setOtpSend] = useState(false);
  return (
    <>
      {otpSend ? (
        <PhonePage2 setOtpSend={setOtpSend} />
      ) : (
        <PhonePage1 setOtpSend={setOtpSend} />
      )}
    </>
  );
};

export default Index;
