import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
interface IscProps {
  gradiantColor: string;
  heading: string;
  maxScore: number;
  userScore: number;
  unlocktext: string;
}

const ProfileScoreCard: React.FC<IscProps> = (props: IscProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate('/profile');
      }}
      className={classNames(
        'bg-gradient-to-b from-gray-100 rounded p-2 py-6 text-center space-y-4 cursor-pointer',
        props.gradiantColor
      )}
    >
      <p className='font-semibold'>{props.heading}</p>
      <p>
        <span className='px-6 py-2 text-sm font-bold rounded-full bg-white/50 text-primary'>{`${props.userScore}/${props.maxScore}`}</span>
      </p>
      <p className='text-sm px-4'>{props.unlocktext}</p>
    </div>
  );
};

export default ProfileScoreCard;
