import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';

import { buttonStyle, errorStyle, inputStyle } from '../../auth/authStyles';
import classNames from 'classnames';
import { axiosSignup } from '../../../config/Axios';

const ContactUs = () => {
  const [serverResponse, setServerResponse] = useState('');

  return (
    <div className='md:px-32'>
      <div className='grid grid-cols-12 space-y-4 md:space-y-0'>
        <div className='col-span-12 md:col-span-6 px-4 space-y-6'>
          <h1 className='font-semibold text-2xl md:text-3xl'>
            Get In Touch With Us
          </h1>
          <Formik
            initialValues={{
              name: '',
              email: '',
              selection: '',
              phone: '',
              message: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              selection: Yup.string().required('Required'),
              email: Yup.string().email('Invalid email').required('Required'),
              phone: Yup.string().matches(
                new RegExp('^[0-9]{10,13}$'),
                'Phone number is not valid'
              ),

              message: Yup.string().min(2, 'Too Short!').required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setServerResponse('');
              // alert(JSON.stringify(values, null, 2));
              axiosSignup
                .post('/contact-us', {
                  userEmail: values.email,
                  userName: values.name,
                  query: values.selection,
                  phone: values.phone,
                  message: values.message,
                })

                .then((res) => {
                  setServerResponse('Your Query has been submitted');
                })
                .catch((err) => {
                  setServerResponse(err?.response?.data?.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form className='space-y-4 lg:space-y-8'>
                <div className='lg:flex gap-4 space-y-4 lg:space-y-0'>
                  <div className='lg:flex-1'>
                    <Field
                      type='text'
                      name='name'
                      placeholder='Name'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='name'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div className='lg:flex-1'>
                    <Field
                      type='email'
                      name='email'
                      placeholder='Email'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                </div>
                <div className='lg:flex gap-4 space-y-4 lg:space-y-0'>
                  <div className='lg:flex-1'>
                    <Field
                      name='selection'
                      as='select'
                      className='border-2 rounded-xl p-2 w-full'
                    >
                      <option value=''>How Can We Help?</option>
                      <option value='Schedule A Demo'>Schedule A Demo</option>
                      <option value='Register'>Register</option>
                      <option value='Customer support / Feedback'>
                        Customer support / Feedback
                      </option>
                      <option value='Other'>Other</option>
                    </Field>
                    <ErrorMessage
                      name='selection'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div className='lg:flex-1'>
                    <Field
                      type='text'
                      name='phone'
                      placeholder='Phone (optional)'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='phone'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                </div>

                <div>
                  <Field
                    as='textarea'
                    name='message'
                    placeholder='Message'
                    className={classNames('h-40', inputStyle)}
                  />
                  <ErrorMessage
                    name='message'
                    component='div'
                    className={errorStyle}
                  />
                </div>
                <div>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={buttonStyle}
                  >
                    {isSubmitting ? 'Sending' : 'Send Message'}
                  </button>
                </div>
                <div className='my-4'>
                  <p className='text-primary'>{serverResponse}</p>
                </div>
              </Form>
            )}
          </Formik>
          <div className='border-t-2 md:hidden'></div>
        </div>
        <div className='col-span-12 md:col-span-6 md:pl-10 pl-4 space-y-3 md:space-y-6'>
          <h2 className='font-semibold text-2xl md:text-3xl'>
            Our Headquarters
          </h2>
          <div className='space-y-4'>
            <p> New Delhi, India</p>
            <p>
              5<sup>th</sup> Floor, A163/1, HK House, <br />
              Lado Sarai, Delhi 110030
            </p>
            <div className='font-semibold'>
              <p>+91 - 8448031917</p>
              <p>connect@belong.education</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
// grid grid-rows-4 grid-cols-1 lg:grid-rows-6  gap-x-4 gap-y-2 lg:gap-y-8
