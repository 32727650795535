// import ExploreInfo from './ExploreInfo';
// import SliderExploreInfo from './SliderExploreInfo';
import ExploreJobs from './ExploreJobs';
import SliderExploreJobs from './SliderExploreJobs';

const ScoreSection = () => {
  return (
    <div className='grid grid-cols-12 mt-24 lg:mt-32'>
      {/* <div className='col-span-12 text-center mb-14'>
        <h1 className='text-textColor-main lg:font-bold font-medium lg:text-3xl text-2xl leading-9 lg:leading-relaxed'>
          Explore
          <span className='text-primary'> Trending Domains</span>
        </h1>
        <p className='text-textColor-main  font-medium lg:text-3xl text-2xl'>
          & Start Applying with Belong Score
        </p>
      </div>
      <div className='col-span-12 hidden lg:flex'>
        <ExploreInfo />
      </div>
      <div className='lg:hidden col-span-12  px-8'>
        <SliderExploreInfo />
      </div>
      <div className='col-span-12 text-center'>
        <button className='text-primary bg-textColor-main lg:px-12 py-2 px-6 rounded-lg lg:my-6 my-4 lg:mb-24 mb-16 font-semibold text-xl'>
          Explore More
        </button>
      </div> */}
      <div className='col-span-12 text-center'>
        <h1 className='text-textColor-main lg:font-bold font-semibold lg:text-3xl text-2xl leading-9 lg:leading-relaxed'>
          Explore
          <span className='text-primary'> Featured Internships and Jobs</span>
        </h1>
      </div>
      <div className='col-span-12 hidden md:block'>
        <div className='w-full mt-12'>
          <ExploreJobs />
        </div>
      </div>

      <div className='md:hidden col-span-12 mt-12 '>
        <SliderExploreJobs />
      </div>
      <div className='col-span-12 text-center'>
        {/* <button className='shadow text-primary bg-textColor-main lg:px-12 py-2 px-6 rounded-lg lg:my-6 lg:mb-16 mb-4 font-semibold text-xl'>
          Explore More
        </button> */}
      </div>
    </div>
  );
};

export default ScoreSection;
