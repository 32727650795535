import React, { useState } from 'react';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaLinkedin,
  FaPencilAlt,
  FaRegCheckCircle,
} from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import Modal from 'react-modal';
import EditContactDetails from './editDetails/EditContactDetails';
import { customStyles } from './ModalStyles';
import { Link } from 'react-router-dom';

type contactDetailProps = {
  country: String;
  email: String;
  contactNumber: String;
  linkedInUrl: String;
  firstName: String;
  lastName: String;
  latestCertification: String;
  currentRole: String;
  phoneVerification: Boolean;
};

const ContactDetails = (props: contactDetailProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='p-2 m-2 bg-gray-50/50 '>
      <div className='font-semibold flex justify-between text-primary'>
        Contact Details *{' '}
        <div onClick={() => openModal()} className='text-lg'>
          <FaPencilAlt />
        </div>
      </div>
      <div className='grid grid-cols-3 gap-2 mt-4'>
        {props.country && (
          <div className='flex'>
            <div className='mt-1 mr-2 text-primary'>
              <FaMapMarkerAlt />
            </div>
            {props.country}
          </div>
        )}
        <div className='flex col-span-2'>
          <div className='mt-1 mr-2 text-primary'>
            <AiOutlineMail />
          </div>
          {props.email}
        </div>
        <div className='flex'>
          {props.contactNumber && (
            <div className='flex'>
              <div className='mt-1 mr-2 text-primary'>
                <FaPhoneAlt />
              </div>
              {props.contactNumber}
            </div>
          )}
          {props.phoneVerification ? (
            <div className='mt-1 ml-2'>
              {' '}
              <FaRegCheckCircle />
            </div>
          ) : (
            <Link
              className='ml-2 text-sm underline underline-offset-4 text-gray-400 hover:text-primary hover:cursor-pointer transition-all ease-in-out duration-300'
              to='/phone-verify'
            >
              Verify Mobile Number
            </Link>
          )}
        </div>
        <div className='flex break-all col-span-3 sm:col-span-2'>
          <div className='mt-1 mr-2 text-primary'>
            <FaLinkedin />
          </div>
          {props.linkedInUrl ? props.linkedInUrl : 'Add linkedin Url'}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditContactDetails
            country={props.country}
            linkedInUrl={props.linkedInUrl}
            closeModal={closeModal}
            firstName={props.firstName}
            lastName={props.lastName}
            latestCertification={props.latestCertification}
            currentRole={props.currentRole}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ContactDetails;
