import MapLayout from './MapLayout';
import MapPart2 from './MapPart2';

const PlacesSection = () => {
  return (
    <div className='hidden lg:block mt-24 lg:mt-32'>
      <div className='h-screen'>
        <div className='text-center mb-12'>
          <p className='text-textColor-main lg:font-semibold font-medium lg:text-4xl text-3xl lg:leading-relaxed leading-9'>
            <span className='text-primary'>Belong Score</span> Has Taken People
            Places
          </p>
          {/* <p className='text-textColor-lighter font-normal text-3xl leading-9'>
            Start Your Journey with Belong Score Now
          </p> */}
        </div>
        <MapLayout />
      </div>
      <MapPart2 />
    </div>
  );
};

export default PlacesSection;
