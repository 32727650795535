import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import ProjectForm from './ProjectForm';

type editWorkProps = {
  allProjects: any;
  closeModal: Function;
  index: number;
  portfolio: any;
};

const EditWorkExperience = (props: editWorkProps) => {
  const [loading, setLoading] = useState(false);
  const portfolio = props.portfolio;
  const index = props.index;
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  function removeProject() {
    setLoading(true);
    const newAllProject = props.allProjects.filter((_: any, idx: any) => {
      return index !== idx;
    });
    updateUser({ projectPortfolio: newAllProject })
      .unwrap()
      .then((res) => closeModal());
  }

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Portfolio <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling this field will make you stand out and enhance your chance to
        get interviews
      </div>
      <div className='mt-4'>Projects</div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            projectName: portfolio.projectName,
            country: portfolio.country,
            projectUrl: portfolio.projectUrl,
            description: portfolio.description,
            startDate: portfolio.startDate,
            endDate: portfolio.endDate,
            currentlyWorking: portfolio.currentlyWorking || false,
          }}
          validationSchema={Yup.object({
            projectName: Yup.string().required('Company Name is required'),
            description: Yup.string().required(
              'Project description is required'
            ),
            startDate: Yup.string().required('Project start date is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let allProjects = [...props.allProjects];
            let currentPortfolio = { ...portfolio };
            currentPortfolio = { ...currentPortfolio, ...values };
            allProjects[index] = currentPortfolio;
            updateUser({ projectPortfolio: allProjects })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <ProjectForm
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='flex justify-end text-red-700 mb-4'>
        <button
          disabled={loading}
          className={loading ? 'text-red-300' : ''}
          onClick={() => removeProject()}
        >
          Remove This Project
        </button>
      </div>
    </div>
  );
};

export default EditWorkExperience;
