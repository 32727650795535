import React from 'react';
import { useGetSummaryQuery } from '../dashboard/panel/panelSlics';
import { getDoaminUrl } from '../dashboard/panel/utils/helper';
import { Link } from 'react-router-dom';

const NoDomain = () => {
  const { data } = useGetSummaryQuery({});
  const domainUrlLink = getDoaminUrl(data);

  return (
    <div>
      Take the Belong Score{' '}
      <span className='text-primary'>
        {' '}
        <Link to={domainUrlLink}>Domain Test</Link>{' '}
      </span>{' '}
      to view your domain categories
    </div>
  );
};

export default NoDomain;
