import React from 'react';
import InstructionsText from './layout/InstructionsText';
import { useStartAptitudeTestMutation } from './AptitudeQuizSlice';
import { useState } from 'react';

type instructionsProps = {
  data: any;
};

const AptitudeInstructions = (props: instructionsProps) => {
  const [loading, setLoading] = useState(false);
  const [startAptitudeTest] = useStartAptitudeTestMutation();
  function startAptitude() {
    setLoading(true);
    startAptitudeTest({ responses: props.data });
  }
  return (
    <>
      <div className='mb-8'>
        <InstructionsText data={props.data} />
        <div className='flex justify-center mt-4 sm:mt-12'>
          <button
            disabled={loading}
            onClick={() => {
              startAptitude();
            }}
            className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
          >
            {loading ? 'Please Wait' : 'Start Test'}
          </button>
        </div>
      </div>
    </>
  );
};

export default AptitudeInstructions;
