import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import EditIndustry from './editDetails/EditIndustry';
import Modal from 'react-modal';
import { customStyles } from './ModalStyles';

type industryProps = {
  industryId: any;
};

const Industry = (props: industryProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const industryId = props.industryId;

  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        Industry*
        <div onClick={() => openModal()} className='text-lg text-primary'>
          <FaPencilAlt />
        </div>
      </div>
      <div>{industryId && industryId.title}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditIndustry industryId={industryId} closeModal={closeModal} />
        </div>
      </Modal>
    </div>
  );
};

export default Industry;
