const AptStartScreenCta = () => {
  return (
    <div className='text-center mb-12 mt-12 lg:mt-0 font-manrope'>
      <p className='text-2xl text-primary lg:leading-10'>
        <span className='font-bold text-primary'>2</span> more steps to getting
        your
        <span className='font-bold text-primary'> Belong Score</span>
      </p>
      <p>Qualify Aptitude and Domain Test to Apply for Opportunities</p>
    </div>
  );
};

export default AptStartScreenCta;
