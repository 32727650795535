import { useState, useEffect } from 'react';
import classNames from 'classnames';

const aboutInfos = [
  {
    index: 0,
    number: 1,
    heading: 'Belong Profile',
    weightage: ' / 10',
    text: 'Showcase your professional journey',
    inactiveClasses:
      'text-[#10ABB7]/60 bg-gradient-to-b  from-[#fff1f533] to-[#ff8fac33]',
    activeClasses:
      'text-[#07818A] bg-gradient-to-b  from-[#ffbbcd99] to-[#ff3e7099]',
  },
  {
    index: 1,
    number: 2,
    heading: 'Aptitude Test',
    weightage: ' / 40',
    text: ' Evaluate your workplace readiness',
    inactiveClasses:
      'text-[#10ABB7]/60 bg-gradient-to-b  from-[#f1f6ff33] to-[#7ba8ff33]',
    activeClasses:
      'text-[#07818A]  bg-gradient-to-b from-[#c9dbff99] to-[#377bff99]',
  },
  {
    index: 2,
    number: 3,
    heading: 'Domain Test',
    weightage: ' / 50',
    text: 'Assess your domain knowledge',
    inactiveClasses:
      'text-[#10ABB7]/60 bg-gradient-to-b  from-[#ffffff33] to-[#39efe333]',
    activeClasses:
      'text-[#07818A] bg-gradient-to-b  from-[#78fff699] to-[#0a948b99]',
  },
];
// const delay = 2;

const AboutInfo = () => {
  const [currentActive, setCurrentActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentActive((currentActive + 1) % 3);
    }, 4000);
    // let timer1 = setTimeout(() => delay * 1000);
    return () => {
      clearInterval(interval);
      // clearTimeout(timer1);
    };
  }, [currentActive]);

  return (
    <div className='md:grid md:grid-cols-3 md:space-x-4 lg:space-x-8 '>
      {aboutInfos.map(
        ({
          index,
          number,
          heading,
          weightage,
          text,
          inactiveClasses,
          activeClasses,
        }) => {
          return (
            <div
              className='mb-6 lg:mb-20'
              key={number}
              // onMouseEnter={() => {
              //   setCurrentActive(index);
              // }}
              // onMouseLeave={() => {
              //   setCurrentActive(null);
              // }}
            >
              <div
                className={classNames(
                  'flex h-full rounded-2xl py-4 px-2 lg:px-4 ',
                  { [`${inactiveClasses}`]: !(currentActive === index) },
                  { [`${activeClasses}`]: currentActive >= index }
                )}
              >
                <p className='font-semibold  text-4xl lg:text-6xl mr-4'>
                  {number}
                </p>
                <div>
                  <p className='font-extrabold  text-lg lg:text-2xl lg:pt-2'>
                    {heading} <span className='font-normal'>{weightage}</span>
                  </p>
                  <p
                    className={classNames(
                      'lg:text-lg mt-2 lg:mt-4',
                      { 'text-textColor-main ': currentActive >= index },
                      {
                        'text-textColor-lightest': !(currentActive === index),
                      }
                    )}
                  >
                    {text}
                  </p>
                </div>
              </div>
              {/* <div className={classNames('mt-8 h-4 w-full ' + bg)}></div> */}
            </div>
          );
        }
      )}
    </div>
  );
};

export default AboutInfo;
