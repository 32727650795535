import InactiveBanner from './InactiveBanner';

function ActiveBanner({ isOpen, setIsOpen }) {
  return (
    <>
      {isOpen ? (
        <>
          <div className='relative space-y-4 lg:space-y-6  pt-12 pb-6 md:py-12 -mx-8 md:-mx-20 flex flex-col justify-center bg-gradient-to-br from-[#E4EEFF] to-[#FFFCEC]'>
            <div className='text-center  px-4 '>
              <p className='text-textColor-main font-semibold lg:text-4xl text-3xl  '>
                <span className='text-primary'> Labels </span>
                Are For Clothes, Not
                <span className='text-primary'> People </span>
              </p>
            </div>

            <div className='text-[#171717] font-medium text-center px-6 md:px-0 md:w-3/4 lg:w-1/2 mx-auto'>
              <p className='lg:hidden'>
                Creating an ecosystem of equal opportunities for all, Join our
                Full Stack Bootcamp to start your journey
              </p>
              <p className='hidden lg:block'>
                Creating an ecosystem of equal opportunities for all, <br />{' '}
                Join our Full Stack Bootcamp to start your journey
              </p>
            </div>
            <div className='text-center'>
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://mycoding.belong.education'
              >
                <button className='w-fit mx-auto bg-gradient-to-r from-[#10ABB7] to-[#813588] text-white font-bold py-2 px-4 lg:px-8 rounded'>
                  Register For Bootcamp
                </button>{' '}
              </a>
            </div>

            <img
              src='https://res.cloudinary.com/belong/image/upload/v1674039433/landing/body10-img_jes6h0.png'
              alt='yolo'
              className='object-contain h-20'
            />
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className='absolute cursor-pointer text-center -top-2 right-[8%] md:top-0  lg:right-[5%] bg-[#CFF4F2] p-1 rounded'
            >
              <p className='h-6 w-6'>X</p>
            </div>
          </div>
        </>
      ) : (
        <InactiveBanner />
      )}
    </>
  );
}

export default ActiveBanner;
