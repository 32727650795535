import React from 'react';

const AboutUs = () => {
  return (
    <div className='md:px-32'>
      <div className='grid grid-cols-12 '>
        <div className='col-span-12 lg:col-span-6 order-2 lg:order-1 space-y-4 lg:space-y-8 px-4 '>
          <h1 className='font-bold text-xl md:text-2xl lg:text-3xl mt-4 md:mt-0'>
            About Us
          </h1>
          <div className='space-y-2 lg:space-y-4 '>
            <p className='lg:text-lg'>
              We enable talent to go global from their rooms.
            </p>
            <p>
              Belong is an employability marketplace that facilitates a seamless
              ecosystem of opportunities for young talent, global employers and
              universities across the globe.
            </p>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-6 order-1 lg:order-2  lg:w-4/5 -mx-2 lg:mx-auto'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1660888819/landing/about_wt7juk.jpg'
            alt='about-belong'
            className='rounded shadow '
          />
        </div>
      </div>
      <div className='grid grid-cols-12  gap-y-8 lg:gap-x-8 px-4  my-10 lg:my-16 lg:leading-7'>
        {/* Student Box */}
        <div className='col-span-12 lg:col-span-4 bg-[#D2EAEB]/60 rounded-lg p-2 pb-4 md:p-6 '>
          <div className='flex gap-4 items-center'>
            <div className='space-y-3'>
              <p className='font-semibold text-lg'>Students</p>
              <p className='text-sm'>
                For young <span className='text-primary'>talent</span> it’s a
                platform which enables them to access virtual work experiences,
                upskilling courses and live projects with organisations
                globally.
              </p>
              <div>
                <a
                  href='https://belongeducation.com/'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='cursor-pointer  text-primary underline underline-offset-4'
                >
                  Know more
                </a>
              </div>
            </div>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1680590411/landing/graduation-svgrepo-com_1_jwj4n7.svg'
                alt='cap'
                className='hidden md:block  w-60 lg:w-72'
              />
            </div>
          </div>
        </div>
        {/* Employers Box */}
        <div className='col-span-12 lg:col-span-4 bg-[#D2EAEB]/60 rounded-lg p-2 pb-4 md:p-6 '>
          <div className='flex gap-4 items-center'>
            <div className='space-y-3'>
              <p className='font-semibold text-lg'>Employers</p>
              <p className='text-sm'>
                The talent marketplace gives
                <span className='text-primary'> Employers</span> a unique
                opportunity engage with an international audience on a single
                platform in critical, strategic and market entry related
                projects.
              </p>
              <div>
                <a
                  href='https://employers.belong.education/'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='cursor-pointer  text-primary underline underline-offset-4'
                >
                  Know more
                </a>
              </div>
            </div>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1680590412/landing/university-svgrepo-com_1_vgnp76.svg'
                alt='cap'
                className='hidden md:block  w-60 lg:w-72'
              />
            </div>
          </div>
        </div>
        {/* University Box */}
        <div className='col-span-12 lg:col-span-4 bg-[#D2EAEB]/60 rounded-lg p-2 pb-4 md:p-6 '>
          <div className='flex gap-4 items-center'>
            <div className='space-y-3'>
              <p className='font-semibold text-lg'>Universities</p>
              <p className='text-sm'>
                <span className='text-primary'> Universities</span> can build
                custom graduate readiness programs relevant to their student
                base and access faculty, industry experts, placement
                opportunities and global academic programs.
              </p>
              <div>
                <a
                  href='https://universities.belong.education/'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='cursor-pointer  text-primary underline underline-offset-4'
                >
                  Know more
                </a>
              </div>
            </div>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1680590411/landing/world-svgrepo-com_1_r5uxia.svg'
                alt='cap'
                className='hidden md:block  w-60 lg:w-72'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
