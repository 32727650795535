import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../userSlice';
import WorkExperienceForm from './WorkExperienceForm';

type editWorkProps = {
  allExperince: any;
  closeModal: Function;
  index: number;
  experince: any;
};

const EditWorkExperience = (props: editWorkProps) => {
  const [loading, setLoading] = useState(false);
  const experince = props.experince;
  const index = props.index;
  const [updateUser] = useUpdateUserMutation();
  const closeModal = props.closeModal;

  function removeExperince() {
    setLoading(true);
    const newAllExp = props.allExperince.filter((_: any, idx: any) => {
      return index !== idx;
    });
    updateUser({ workExperience: newAllExp })
      .unwrap()
      .then((res) => closeModal());
  }

  return (
    <div className='bg-white'>
      <div className='flex justify-between w-full text-primary'>
        Work Experience
        <button onClick={() => closeModal()}>X</button>
      </div>
      <div className='text-xs text-red-600'>
        Filling all the fields is mandatory if work experince is more than 6
        Months
      </div>
      <div className='mt-4'>Experience</div>
      <div className='mt-6'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            companyName: experince.companyName,
            country: experince.country,
            title: experince.title,
            description: experince.description,
            startDate: experince.startDate,
            endDate: experince.endDate,
            currentlyWorkingHere: experince.currentlyWorkingHere,
          }}
          validationSchema={Yup.object({
            companyName: Yup.string().required('Company Name is required'),
            country: Yup.string().required('Country is required'),
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            startDate: Yup.string().required('Start Date is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let allExperince = [...props.allExperince];
            let currentExperince = { ...experince };
            currentExperince = { ...currentExperince, ...values };
            allExperince[index] = currentExperince;
            updateUser({ workExperience: allExperince })
              .unwrap()
              .then((res) => closeModal());
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form className='space-y-6 rounded-xl'>
              <WorkExperienceForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-1 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='flex justify-end text-red-700 mb-4'>
        <button
          disabled={loading}
          className={loading ? 'text-red-300' : ''}
          onClick={() => removeExperince()}
        >
          Remove This Experience
        </button>
      </div>
    </div>
  );
};

export default EditWorkExperience;
