import React, { useState } from 'react';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import AddEducation from './editDetails/education/AddEducation';
import IndividualEducation from './IndividualEducation';
import { customStyles } from './ModalStyles';

type educationProps = {
  educationDetail: any;
};

const Education = (props: educationProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const educationDetail = [...props.educationDetail];
  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        Education*{' '}
        <div className='text-lg text-primary'>
          <div className='flex'>
            <button onClick={() => openModal()}>
              <FaPlus className='mr-4' />
            </button>
            {educationDetail.length > 0 && (
              <button onClick={() => setShowEdit(!showEdit)}>
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
      </div>
      {educationDetail
        .sort((a: any, b: any) => {
          const firstDate = +new Date(a.startDate);
          const secondDate = +new Date(b.startDate);
          return secondDate - firstDate;
        })
        .map((education: any, index: number) => {
          return (
            <IndividualEducation
              key={index}
              showEdit={showEdit}
              education={education}
              index={index}
              allEducationDetail={educationDetail}
            />
          );
        })}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <AddEducation
            closeModal={closeModal}
            currentEducation={educationDetail}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Education;
