import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'query-string';

import useDomainTestContext from '../../../../../hooks/useDomainTestContext';
import {
  useCheckIncompleteQuery,
  useGetQuizQuery,
  useStartTestMutation,
} from '../../QuizSlice';
import classNames from 'classnames';

const StartTestInstructions = () => {
  const navigate = useNavigate();
  const { state } = useDomainTestContext();
  const { refetch } = useCheckIncompleteQuery();

  useEffect(() => {
    //If no domain,less than 3 skills has been selected before coming to this screen
    if (!(state.areaId && state.skillIds.length >= 2)) {
      navigate('../select-skills');
    }
  }, [state.areaId, state.skillIds, navigate]);

  const params = stringify(state, { arrayFormat: 'index' });

  const { isLoading, isSuccess, isError, data } = useGetQuizQuery(params);

  const startTestObject = {
    domain: state.areaId,
    skills: state.skillIds,
    responses: data,
  };

  const [startTest, { isLoading: isProcessing, isError: startQuizError }] =
    useStartTestMutation();

  return (
    <div>
      <p>{(isLoading || isProcessing) && 'Loading...'}</p>
      <p>{(isError || startQuizError) && 'Error'}</p>
      <div className='m-1 sm:m-8 bg-gray-50 p-2 sm:p-8'>
        <div className='mb-2 sm:mb-6'>
          The Domain Test evaluates your domain specific concepts and skill sets
        </div>
        <div className='ml-2'>
          <div className='text-primary font-semibold mb-4'>Test Format</div>
          <div className='mb-2 sm:mb-6'>
            Total Questions: 10 MCQs/ skill * no. of skills
          </div>
          <div className='text-primary font-semibold mb-4'>
            General Instructions
          </div>
          <ul className='list-disc'>
            <li>Click on "Get Started" to begin the assessment</li>
            <li>
              You can navigate through questions using Previous/ Next Buttons
            </li>
            <li>
              Remember to finish on time and click on the submit button. If the
              timer runs out while you're still finishing the assessment, your
              answer will be auto-submitted
            </li>
            <li>
              You can preview and edit your answers at the end of the all the
              questions if you are able to finish all your sections before a
              total time of 20 mins
            </li>
            <li>
              After submitting your assessment, you will be able to see your
              scores
            </li>
          </ul>
          <div className='mt-2 sm:mt-6 ml-1'>Note:</div>
          <ul className='list-disc'>
            <li>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            <li>
              In case of any network issues, please raise a query to the Belong
              Team
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classNames('flex justify-center mt-4 sm:mt-12', {
          hidden: !(isSuccess && data),
        })}
      >
        <button
          disabled={isProcessing}
          onClick={() => {
            startTest(startTestObject)
              .unwrap()
              .then((res) => {
                refetch();
              });
          }}
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
        >
          Start Test
        </button>
      </div>
    </div>
  );
};

export default StartTestInstructions;
