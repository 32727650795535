import React, { useState } from 'react';
import { FaPencilAlt, FaCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import Modal from 'react-modal';
import EditProject from './editDetails/projects/EditProject';
import { customStyles } from './ModalStyles';

type individualProjectProps = {
  portfolio: any;
  allProjects: any;
  index: number;
  showEdit: Boolean;
};

const IndividualProject = (props: individualProjectProps) => {
  const portfolio = props.portfolio;
  const endDateText = portfolio.currentlyWorkingHere
    ? 'Till Now'
    : portfolio.endDate && format(new Date(portfolio.endDate), 'MMM u');

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='flex m-2 p-2 bg-white'>
      <div>
        <FaCircle className='text-primary mr-4 mt-2 text-sm' />
      </div>
      <div className='w-full'>
        <div className='flex justify-between'>
          <div className='font-semibold'>{portfolio.projectName}</div>
          <div className='flex'>
            {format(new Date(portfolio.startDate), 'MMM u')} - {endDateText}
            {props.showEdit && (
              <button
                onClick={() => openModal()}
                className='text-lg text-primary ml-3'
              >
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
        <div>
          <ul className='list-disc text-sm mt-1'>
            <li>{portfolio.description}</li>
          </ul>
        </div>
        <a
          href={portfolio.projectUrl}
          className='text-xs text-primary break-all'
          target='_blank'
          rel='noreferrer'
        >
          {portfolio.projectUrl}
        </a>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <EditProject
            closeModal={closeModal}
            allProjects={props.allProjects}
            index={props.index}
            portfolio={portfolio}
          />
        </div>
      </Modal>
    </div>
  );
};

export default IndividualProject;
