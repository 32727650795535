import { Outlet } from 'react-router-dom';
import Navbar from 'components/dashboard/Navbar';

const NoSidebarLayout = () => {
  return (
    <div className='p-2'>
      <Navbar />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default NoSidebarLayout;
