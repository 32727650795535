import { BsStopwatch } from 'react-icons/bs';
import classNames from 'classnames';

interface StartScreenCardProps {
  stepNo: string;
  stepColor: string;
  bgColor: string;
  heading: string;
  score: string;
  color: string;
  time: string;
  text: string;
}

const StartScreenCard = (props: StartScreenCardProps) => {
  return (
    <div className='mt-6 mx-auto'>
      <p
        className={classNames(
          'text-center  font-bold text-xl',
          props.stepColor
        )}
      >
        {props.stepNo}
      </p>
      <div
        className={classNames(
          'h-2 lg:w-72 w-60 mx-10 lg:my-4 my-2',
          props.bgColor
        )}
      ></div>
      <div
        className={classNames(
          'p-6 px-10 lg:w-72 w-60 mx-10 text-center font-manrope bg-gradient-to-b from-gray-100',
          props.color
        )}
      >
        <p className='font-bold text-lg'>{props.heading}</p>
        <div className='bg-white/60 border-1 lg:w-20 w-14 py-2 mx-auto text-primary font-bold lg:my-8 my-4 text-base rounded-2xl'>
          {props.score}
        </div>
        <div className='flex justify-center items-center  font-bold text-base lg:mb-4 mb-2 text-dark/60'>
          <BsStopwatch />
          {props.time}
        </div>
        <p className='text-xs h-12 '>{props.text}</p>
      </div>
    </div>
  );
};

export default StartScreenCard;
