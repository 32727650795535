// import { Link } from 'react-router-dom';
import HowToInfo from './HowToInfo';

const HowToSection = () => {
  return (
    <div className='grid grid-cols-12 space-y-12 lg:space-y-2 mt-24 lg:mt-32 bg-gray-50 -mx-4 md:-mx-0 p-4 md:p-0 md:bg-white'>
      <div className='col-span-12 lg:col-span-5'>
        <div className='sticky top-10'>
          <h1 className='text-textColor-main font-semibold lg:text-3xl text-2xl lg:leading-[45px]'>
            How to find your perfect job and internship with
            <span className='text-primary'> Belong Score</span>
          </h1>
        </div>
      </div>
      <div className='col-span-12 lg:col-span-6 lg:col-start-7'>
        <div className='md:px-12 lg:px-16'>
          <HowToInfo />
        </div>
      </div>
    </div>
  );
};

export default HowToSection;
