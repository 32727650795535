import React, { useState } from 'react';
import { axiosApi } from '../../../config/Axios';
import { useUpdateUserMutation } from '../userSlice';

type imageProps = {
  closeModal: Function;
  imageUrl: string;
};

const DeleteImage = (props: imageProps) => {
  const [loading, setLoading] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  async function deleteFile() {
    setLoading(true);
    const data = { key: props.imageUrl };
    const result = await axiosApi.delete('/aws-upload/delete', { data });
    if (result.status === 200) {
      updateUser({ imageUrl: '' })
        .unwrap()
        .then((res) => props.closeModal());
    }
    setLoading(false);
  }

  return (
    <div>
      <div className='text-lg font-bold'>
        Do you want remove Profile Image?{' '}
      </div>
      <div className=' mt-4 flex justify-around'>
        <button
          disabled={loading}
          onClick={() => deleteFile()}
          className='text-red-600 border border-red-500 px-4 py-1 rounded'
        >
          {loading ? 'Removeing...' : 'Delete'}
        </button>
        <button
          onClick={() => props.closeModal()}
          className='border border-gray-500 px-4 py-1 rounded'
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteImage;
