import React from 'react';
import { VscLoading } from 'react-icons/vsc';
import { BsCircle } from 'react-icons/bs';
import classNames from 'classnames';

interface props {
  data: any;
}
const TotalAttempts: React.FC<props> = ({ data }) => {
  const dataToProcess = data?.domain.totalAttempts
    ? data?.domain
    : data?.aptitude;

  const totalAttempts = dataToProcess?.totalAttempts;

  return (
    <div className='flex-1 bg-gradient-to-b from-gray-100 to-primary-lighter shadow-lg rounded-xl'>
      <div className='flex p-4'>
        <div className='grid place-items-center px-8 relative'>
          <div className={classNames({ hidden: totalAttempts !== 1 })}>
            {/* Half */}
            <VscLoading className='text-7xl font-semibold rotate-45	' />
          </div>

          <div className={classNames({ hidden: totalAttempts === 1 })}>
            {/* Full */}
            <BsCircle className='text-7xl font-semibold' />
          </div>

          <p className='absolute top-1/2 left-1/2 -translate-x-1/2	-translate-y-1/2	'>
            {dataToProcess?.totalAttempts} / 2
          </p>
        </div>
        <div className='text-center flex-1'>
          <p className='font-semibold'>Total Attempts</p>
          <p className='text-xs'>
            Chances Left to re-attempt and improve Belong Score.
          </p>
        </div>
      </div>

      <div className='hidden flex justify-between items-center shadow rounded-xl mt-4 bg-primary-lightest px-4 py-2'>
        <p className='text-xs w-3/5'>
          Unlock More Attempts with Belong Booster
        </p>
        <p className='font-semibold'>Notify me</p>
      </div>
    </div>
  );
};

export default TotalAttempts;
