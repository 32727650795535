import React, { useState, useEffect } from 'react';

const TransitionProgress = ({ steps, direction }) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    let timeOut;
    let interval;
    function resumeeAnimation() {
      timeOut = setTimeout(() => {
        setWidth(0);
        animate();
      }, 4000);
    }

    function animate() {
      interval = setInterval(() => {
        if (width >= 100) {
          clearInterval(interval);
          resumeeAnimation();
        } else {
          setWidth(width + 1.3);
        }
      }, 100);
    }
    animate();
    return () => {
      clearInterval(interval);
      clearTimeout(timeOut);
    };
  }, [width]);

  const inactiveStyle = 'h-6 w-6 bg-light rounded-full';
  const activeStyle = 'h-6 w-6 rounded-full bg-primary';
  return (
    <div className='relative my-12'>
      <div className='flex justify-between items-center  h-2 bg-light  rounded-xl'>
        <span className={activeStyle}></span>
        <span className={width >= 50 ? activeStyle : inactiveStyle}></span>
        <span className={width >= 100 ? activeStyle : inactiveStyle}></span>
      </div>
      <div
        style={{ width: `${width}%` }}
        className='absolute h-2 bg-primary top-0 rounded-xl transition ease-in-out duration-300'
      ></div>
    </div>
  );
};

export default TransitionProgress;
