import { useQuery } from '@tanstack/react-query';
import { axiosApi } from '../config/Axios';

function fetchCountries() {
  return axiosApi.get('/locations/countries');
}
const useCountriesData = () => {
  return useQuery(['fetchCountries'], fetchCountries, {
    select: (data: any) => {
      const transformedData = data.data.data;
      return transformedData;
    },
  });
};
export default useCountriesData;
