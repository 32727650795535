// import { AiOutlineMenu } from 'react-icons/ai';
import Navbar from './Navbar';
import { motion, AnimatePresence, useCycle } from 'framer-motion';
import { MenuToggle } from './Toggle';
import { MobileNavbar } from './MobileNavbar';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 90% 46px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: 'circle(30px at 90% 46px )',
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };
  return (
    <>
      <div className='relative flex justify-between pt-4 lg:pt-8 -mx-4 px-4 md:-mx-20 md:px-20 lg:-mx-0 lg:px-0'>
        <div>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
            alt='logo'
            className='hidden lg:block w-48'
          />
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
            alt='Logo'
            className='lg:hidden w-16'
          />
        </div>
        <div className=' flex items-center'>
          <div className='hidden lg:block'>
            <Navbar />
          </div>
          <AnimatePresence>
            <motion.nav
              initial={false}
              animate={isOpen ? 'open' : 'closed'}
              custom='100%'
              className='absolute top-0 right-0 lg:hidden w-full  '
            >
              <motion.div
                className='absolute top-0 right-0  bg-primary-lighter w-full h-full'
                variants={sidebar}
              >
                <MenuToggle toggle={() => toggleOpen()} />
              </motion.div>
              <MobileNavbar isOpen={isOpen} />
            </motion.nav>
          </AnimatePresence>
        </div>
        <div className='hidden lg:grid grid-flow-col space-x-6 items-center  '>
          <Link
            to='/login'
            className='border text-primary font-semibold px-6 py-1 rounded-lg'
          >
            Login
          </Link>
          <Link
            to='/signup'
            className='border text-primary font-semibold px-6 py-1 rounded-lg'
          >
            Signup
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
