import React from 'react';
// import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='flex flex-col space-x-14 lg:flex-row text-dark/60'>
      <div>
        <a
          className='hover:text-black'
          href='https://employers.belong.education/'
        >
          Employer
        </a>
      </div>
      <div>
        <a
          className='hover:text-black'
          href='https://universities.belong.education/'
        >
          University
        </a>
      </div>
      {/* <div className='text-secondary font-poppins font-bold'>
        <Link to='/login'>Login</Link>
      </div>
      <Link
        to='/signup'
        className='bg-primary/70 text-white rounded-lg px-4 py-1 font-poppins font-bold shadow'
      >
        Signup
      </Link> */}
    </div>
  );
};

export default Navbar;
