import React, { useState } from 'react';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import AddWorkExperience from './editDetails/workExperience/AddWorkExperience';
import IndividualExperience from './IndividualExperience';
import { customStyles } from './ModalStyles';

type workExpProps = {
  workExp: any;
};

const WorkExperience = (props: workExpProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const workExp = [...props.workExp];

  let timeDiff = 0;

  props.workExp.forEach((exp: any) => {
    const date1 = new Date(exp.startDate);
    const date2 = exp.endDate ? new Date(exp.endDate) : new Date(Date.now());
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    timeDiff = timeDiff + Difference_In_Days;
  });

  return (
    <div className='p-2 m-2 bg-gray-50/50'>
      <div className='text-primary font-semibold flex justify-between'>
        Work Experience*
        <div className='text-lg text-primary'>
          <div className='flex'>
            <button onClick={() => openModal()}>
              <FaPlus className='mr-4' />
            </button>
            {workExp.length > 0 && (
              <button onClick={() => setShowEdit(!showEdit)}>
                <FaPencilAlt />
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        {' '}
        {Math.floor(timeDiff / 365)
          ? `${Math.floor(timeDiff / 365)} Years`
          : ''}{' '}
        {Math.floor((timeDiff % 365) / 30)
          ? `${Math.floor((timeDiff % 365) / 30)} Months`
          : ''}{' '}
      </div>
      {workExp
        .sort((a: any, b: any) => {
          const firstDate = +new Date(a.startDate);
          const secondDate = +new Date(b.startDate);
          return secondDate - firstDate;
        })
        .map((experince: any, index: number) => {
          return (
            <IndividualExperience
              key={index}
              showEdit={showEdit}
              experince={experince}
              index={index}
              workExp={workExp}
            />
          );
        })}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <AddWorkExperience
            closeModal={closeModal}
            currentExperince={props.workExp}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WorkExperience;
