import { useState } from 'react';
import { axiosApi } from '../../../config/Axios';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from 'components/profile/userSlice';

type phonePageProps = {
  setOtpSend: Function;
};

const PhonePage2 = (props: phonePageProps) => {
  const { refetch } = useGetUserQuery();

  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpResend, setOtpResend] = useState(false);
  const [loading, setloading] = useState(false);

  async function verifyOtp() {
    setloading(true);
    const data = { otp: otp };
    try {
      const result = await axiosApi.post('/verify/otp', data);
      if (result.status === 200) {
        refetch();
        setErrorMessage('opt verified');
        navigate('/profile');
      }
    } catch {
      setErrorMessage('Please try after some time');
    } finally {
      setloading(false);
    }
  }

  async function reSendOtp() {
    setloading(true);
    try {
      const result = await axiosApi.post('/verify/resend-otp');
      if (result.status === 200) {
        setOtpResend(true);
      }
    } catch {
      setErrorMessage('Resend fail');
    } finally {
      setloading(false);
    }
  }

  return (
    <div className='grid grid-cols-12'>
      <div className='hidden md:block col-span-5 -mx-4'>
        <div className='p-2 bg-gradient-to-b from-[#193257] to-[#0A1626] '>
          <div>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1661345573/b2c/Number_Verification_OTP_Screen_ptyx65.png'
              alt='otp-section'
              className='mx-auto mt-8 object-contain h-96'
            />
          </div>
          <p className='text-white text-center text-lg md:text-2xl font-bold  mb-16 md:mb-28 md:w-3/5 mx-auto mt-8 md:mt-14'>
            Enrol in <span className='text-primary'> Industry Projects </span>
            to build your skill portfolio
          </p>
        </div>
      </div>
      <div className='col-span-12 md:col-span-7 font-manrope font-bold text-xl'>
        <div className='mt-8 lg:mt-20 p-4 md:p-8'>
          <p
            onClick={() => props.setOtpSend(false)}
            className='text-primary sm:ml-6 lg:ml-28'
          >
            Back
          </p>
          <p className='text-center mt-6 '>
            Enter the OTP received on the registered mobile number
          </p>
          <div className='flex space-x-4 justify-center  mt-6'>
            <input
              className='shadow p-2 border border-gray-400 focus:border-black rounded mx-2 w-1/2'
              type='number'
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div className='text-center text-red-600 text-xs'>{errorMessage}</div>
          <div className='text-center mt-8 '>
            <button
              disabled={loading}
              onClick={() => verifyOtp()}
              className='border-2 border-primary text-primary md:px-8 px-4 py-2 rounded-xl'
            >
              {loading ? 'Updating...' : 'Verify'}
            </button>
          </div>
          {otpResend ? (
            <p>OTP resend successfully</p>
          ) : (
            <button
              disabled={loading}
              onClick={() => reSendOtp()}
              className='text-center w-full lg:mt-10 mt-6'
            >
              OTP not received?
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhonePage2;
