export const FAQ = [
  {
    question: 'What is Belong score?',
    content: [
      {
        type: 'text',
        content:
          'Belong Score is a holistic assessment that includes three components.',
      },
      {
        type: 'list',
        content: [
          'It shows your professional history (Belong Profile).',
          'It evaluates your workplace readiness (Aptitude Test)',
          'It evaluates your domain knowledge (Domain Test).',
        ],
      },
      {
        type: 'text',
        content:
          'In short, it is the new CV that holistically shows your knowledge and skills to employers',
      },
    ],
  },
  {
    question: 'Who can take the Belong Score?',
    content: [
      {
        type: 'text',
        content:
          'Any university student or fresher who is looking for jobs or internships can take the Belong Score, provided you have a stable internet connection to take the assessment smoothly.',
      },
      {
        type: 'text',
        content:
          'You must be above 18 years of age to apply for a job with Belong Score. ',
      },
    ],
  },
  {
    question: 'How do I take a Belong Score and apply for jobs?',
    content: [
      {
        type: 'text',
        content:
          'The process to take Belong Score assessment and apply for jobs or internships is simple:',
      },
      {
        type: 'list',
        content: [
          'Sign up on the Belong platform',
          'Enter your professional history on the Belong dashboard.',
          'Take the 30 minutes Aptitude Test.',
          'Select one or upto three domains and take the 50 minutes domain test.',
          'Get your Belong Score and start applying for jobs or internships.',
        ],
      },
    ],
  },
  {
    question: 'How does Belong Score work?',
    content: [
      {
        type: 'text',
        content:
          'Belong Score reflects your professional potential to employers. In other words, employers trust and use Belong Score during their hiring process because it is the proof of your employability, knowledge and skills.',
      },
      {
        type: 'text',
        content:
          'Remember, when employers assess candidates, they see how well your performance is in different components of the Belong Score assessment. The better the Belong Score, the better are the chances of you getting hired.',
      },
    ],
  },
  {
    question: 'What is the minimum criteria of Belong Score to apply for jobs?',
    content: [
      {
        type: 'text',
        content:
          'To apply for jobs and internships, you are required to pass all the individual components, you need to score thefollowing: ',
      },
      {
        type: 'list',
        content: [
          'At least 25/40 in the Aptitude Test',
          'At least 35/50 in the Domain Test,',
          'At least 5/10 in your Belong Profile',
        ],
      },
    ],
  },
  {
    question:
      ' Can I choose multiple domains while taking the Domain Test in the Belong Score?',
    content: [
      {
        type: 'text',
        content:
          'Yes, you can choose upto 3 domains while taking the Domain Test and all the scores will be shown on your Belong dashboard. Your Belong Score will reflect scores of the domain test that is relevant to the domain of the job or internship you are applying to.',
      },
    ],
  },
  {
    question: ' What happens if I do not pass the Belong Score assessment?',
    content: [
      {
        type: 'text',
        content:
          'If you do not pass in your first attempt, you can re-attempt the Belong Score assessment after 15 days. ',
      },
      {
        type: 'text',
        content:
          'You can also take the Belong Booster that allows you to retake the test thrice each after a gap of 7 days. Taking the Belong Booster also includes various features that will help you score better.',
      },
    ],
  },
  {
    question: 'How can I improve my Belong Score?',
    content: [
      {
        type: 'text',
        content:
          'To improve your Belong Score, you can opt for priced features like Belong Booster that allow you to enhance your knowledge and up-skill yourself to secure better jobs and internships.',
      },
    ],
  },
  {
    question: 'Do I need to pay to take the Belong Score?',
    content: [
      {
        type: 'text',
        content:
          'Belong Score is absolutely free for everyone. However, some features such as Belong Booster and Belong Internship are priced as they are geared to improve your Belong Score.',
      },
    ],
  },
  // {
  //   question: 'What is Belong Booster?',
  //   content: [
  //     {
  //       type: 'text',
  //       content:
  //         'Belong Booster is a priced feature that helps you improve your Belong Score. ',
  //     },
  //     {
  //       type: 'text',
  //       content:
  //         'It includes 2 assured interviews, sessions on placement readiness, unlimited capstone industry projects and a priority pass to a knowledge repository that you can use to ace the Belong Score. ',
  //     },
  //     {
  //       type: 'text',
  //       content:
  //         'Belong Booster also lets you retake the Belong Score assessment thrice each after a gap of 7 days in case you need to. ',
  //     },
  //     {
  //       type: 'text',
  //       content:
  //         'With Belong Booster, your profile will also show as a ‘Belong Verified Profile’ to employers which adds more value to your profile. ',
  //     },
  //   ],
  // },
  // {
  //   question: 'What is Belong Internship?',
  //   content: [
  //     {
  //       type: 'text',
  //       content:
  //         'Belong Internship is for students and freshers who wish to get more work experience. It is a priced feature that is designed to be a cohort-based training programme. ',
  //     },
  //     {
  //       type: 'text',
  //       content:
  //         'With Belong Internship, you get to work on a global industry project with the support and guidance of experienced industry experts. You will get to upskill yourself, retake the Belong Score assessment and finally get 2 assured interviews for jobs or internships.',
  //     },
  //   ],
  // },
];
