import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {
//   ApolloClient,
//   InMemoryCache,
//   ApolloProvider,
//   createHttpLink,
// } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const httpLink = createHttpLink({
//   uri: 'https://belong-api-b2c-w9fucibvfxdyiyd.herokuapp.com/api/graphql',
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token =
//     'eyJraWQiOiJCbVFFZ0txRWoxRis3TTJHVnEybXZXSHNzbEtHYzRJczVnN29jNTBRaHAwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJjMTk4ZGY2Ni1hMmIzLTRlZWMtODAwNC1kNjRmNDI5NGI4YjUiLCJldmVudF9pZCI6IjVlZWYxMDdkLTdhMWItNDRiMC1hMWM4LWRlYjJlNDQ3ZTU3YiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NTc3ODE0MjEsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX2g1T2V4VmpiMCIsImV4cCI6MTY1Nzg2ODEzMSwiaWF0IjoxNjU3NzgxNzMxLCJqdGkiOiJjZTQzZDBmNS00YWZkLTQwYTAtODhhNC1lYjdjMmE2YTcyNzIiLCJjbGllbnRfaWQiOiIyMDczOGljZGZuNTM4a2lldWV0cXJham9qMiIsInVzZXJuYW1lIjoibXVrZXNoYmhhbXU5NkBnbWFpbC5jb20ifQ.d9VD5syZogqaj8gRk1kRchG5TQ4G_AURUOzSEAbhH5N0dtCHbUtjbi3wrl021TJGjUAEm0ew73Pf5Q5-IKoVbPMLDNFXVy7LUfnRz_80zDytI4t9LWPuXjJOW-1HlTQifTu-SgsbYkj49fnKeaPOP2JL9X1HmfrV0WMo573f8-srxLLN35BkGwOt89ELXoiwF5E5zSc11b287D6YzvO5EhznLbsMObB0HJKj_sQ0lIcRJfQGsK1pfMcM-CLe4xMZsM82y1o04i3FT8SkbldaHVm4fPMXb6dQuDmn7KrdXUsU4eXff3Dk-hlLmd-XsQ0z_9YdEQ87tL4qao8AP7UBKw';
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       'x-belong-api-token': token,
//     },
//   };
// });

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),
// });

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client}>
      <App />
    </ApolloProvider> */}
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
