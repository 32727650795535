import React, { useEffect } from 'react';
import { useDomainTestScoreQuery } from './QuizSlice';
import ShowDomainScore from './ShowDomainScore';
import Loading from 'components/global/layout/Loading';
import { useGetSummaryQuery } from 'components/dashboard/panel/panelSlics';

const DomainScoreCard = () => {
  const { isLoading, isSuccess, data } = useDomainTestScoreQuery();
  const { refetch } = useGetSummaryQuery({});

  const condition = Boolean(data);
  //Fetch summary so that user sees updated score after navigating to the home screen
  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && !condition && <div>No Score Found</div>}
      {isSuccess && condition && <ShowDomainScore data={data} />}
    </>
  );
};

export default DomainScoreCard;
