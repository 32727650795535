import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 5,
  //   },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const exploreJobsInfos = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1674038979/landing/Goa_Option_1_i9sy2r_s6hmln.png',
    heading: 'Work In Goa',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1649316629/uploaded_resources/Build3_logo_muhytz.png',
    text: '30+ Companies',
    link: '/jobs?city[0]=Goa',
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213666/uploaded_resources/delhi_ggwdfg.png',
    heading: 'Work from Home',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1658212962/uploaded_resources/tcs_fleogr.png',
    text: '150+ Companies',
    link: '/jobs?jobType[0]=Remote',
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213697/uploaded_resources/global_nkojj3.png',
    heading: 'Work Globally',
    companyLogo:
      'https://res.cloudinary.com/belong/image/upload/v1658213596/uploaded_resources/wework-labs_pau78o.png',
    text: '70+ Companies',
    link: '/jobs?jobType[0]=Remote',
  },
];
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={false}
      // customTransition='all .5'
      // transitionDuration={4000}
      containerClass='carousel-container'
      arrows={false}
      deviceType={deviceType}
      // dotListClass='custom-dot-list-style'
      itemClass='carousel-item-padding-40-px'
    >
      {exploreJobsInfos.map(
        ({ id, image, heading, companyLogo, text, link }) => {
          return (
            <div
              key={id}
              className='rounded-3xl text-center border-2  pb-4 mx-4 '
            >
              <Link to={link}>
                <img
                  src={image}
                  alt='opportunity'
                  className='object-cover h-32 w-full rounded-2xl'
                />
                <div className='grid content-between'>
                  <p className='text-secondary lg:font-semibold text-center font-normal lg:text-2xl text-lg mt-2'>
                    {heading}
                  </p>
                  <img
                    src={companyLogo}
                    alt='logo-here'
                    className='object-contain w-32 my-4 mx-auto'
                  />
                  <p className='lg:font-semibold font-medium text-sm lg:text-base text-textColor-main'>
                    {text}
                  </p>
                </div>
              </Link>
            </div>
          );
        }
      )}
    </Carousel>
  );
};

export default Simple;
