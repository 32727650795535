import React from 'react';
import classNames from 'classnames';
import useAptitudeTestResponsesContext from '../../../../../../hooks/useAptitudeTestResponsesContent';

const QuestionCell: React.FC<{
  question: any;
  num: number;
  closeModal: any;
}> = ({ question, num, closeModal }) => {
  const visited = question.status === 'visited';
  const answered = question.status === 'answered';
  const { dispatch } = useAptitudeTestResponsesContext();
  return (
    <div
      onClick={() => {
        dispatch({
          type: 'UPDATE_CURRENT_QUESTION',
          payload: num - 1,
        });
        closeModal && closeModal();
      }}
      className={classNames(
        'bg-gradient-to-t from-gray-100 rounded text-center grid place-items-center cursor-pointer h-12 w-12',
        { 'to-bgIndigo': answered },
        { 'to-red-400': visited },
        { 'bg-gray-100': !answered && !visited }
      )}
    >
      {num}
    </div>
  );
};

export default QuestionCell;
