import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { axiosApi } from '../../../config/Axios';
import { Link } from 'react-router-dom';

type phonePageProps = {
  setOtpSend: Function;
};

const PhonePage1 = (props: phonePageProps) => {
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setloading] = useState(false);

  async function sendOtp() {
    setloading(true);
    const data = { contactNumber: '+' + phone };
    try {
      const result = await axiosApi.post('/user/addPhoneNumber', data);
      if (result.status === 200) {
        props.setOtpSend(true);
      }
    } catch {
      setErrorMessage('Please try after some time');
    } finally {
      setloading(false);
    }
  }

  return (
    <div className='grid grid-cols-12'>
      <div className='hidden md:block col-span-5 -mx-4'>
        <div className='p-2 bg-gradient-to-b from-[#193257] to-[#0A1626] '>
          <div>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1661345573/b2c/Number_Verification_Image_xuqzee.png'
              alt='phone-verify'
              className='mx-auto mt-8 object-contain h-96'
            />
          </div>
          <p className='text-white text-center text-lg md:text-2xl font-bold  mb-16 md:mb-28 md:w-3/5 mx-auto mt-8 md:mt-14'>
            Earn
            <span className='text-primary'> Belong points </span>
            to improve skills and score
          </p>
        </div>
      </div>
      <div className='md:col-span-7 col-span-12  font-manrope font-bold text-xl '>
        <div className='lg:mt-10 mt-6'>
          <div className='flex justify-end lg:pr-20 pr-10'>
            <Link to='/profile' className='text-primary hover:pointer '>
              Skip
            </Link>
          </div>
          <p className='text-center lg:mt-6 mt-2'>
            Verify your mobile number to get started
          </p>
          <div className='md:w-96 w-auto mt-8 md:mx-auto '>
            <div className='bg-primary/10 flex lg:px-4 px-2 mx-4 lg:mx-0 py-2'>
              <PhoneInput
                value={phone}
                onChange={(phone) => setPhone(phone)}
                preferredCountries={['in', 'za']}
                country={'in'}
                inputStyle={{ width: '100%' }}
                countryCodeEditable={false}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
              />
            </div>
          </div>
          <div className='text-center text-red-600 text-xs'>{errorMessage}</div>
          <div className='text-center mt-8 '>
            <button
              disabled={loading}
              onClick={() => sendOtp()}
              className='border-2 border-primary text-primary lg:px-8 px-4 py-2 rounded-xl hover:bg-primary-lightest'
            >
              {loading ? 'Sending...' : 'Send OTP'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhonePage1;
