import React from 'react';
import { Link } from 'react-router-dom';

const NoSkills = () => {
  return (
    <div className='bg-primary-lightest rounded pb-12 m-2'>
      <div className='text-center font-semibold text-primary pt-4 my-4'>
        Skills
      </div>
      <div className='bg-white mx-4 sm:mx-2 md:mx-4 my-4 py-6 rounded'>
        <div className='text-center px-4 sm:px-2 md:px-4 mb-8'>
          Unlock skill badges and skill level certifications with{' '}
          <span className='text-primary'>
            <button>Belong Score</button>
          </span>
        </div>
        <div className='flex justify-center my-6'>
          <Link
            to='/belong-score'
            className='border border-primary rounded-lg text-primary font-semibold px-6 py-1 shadow-lg'
          >
            Start Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoSkills;
