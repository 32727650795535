import React from 'react';
import FeaturedCard from './FeaturedCard';

const Index = () => {
  return (
    <div>
      <p className='text-2xl text-center mb-4'>
        Explore <span className='text-secondary'>Featured</span> Opportunities
      </p>
      <div className='sm:flex gap-4 justify-evenly space-y-4 sm:space-y-0'>
        <FeaturedCard
          heading='Work in Goa'
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1661340003/landing/Goa_Option_1_i9sy2r.jpg'
          companies={30}
          link='/jobs?city[0]=Goa'
        />
        <FeaturedCard
          heading='Work from Home'
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1658213666/uploaded_resources/delhi_ggwdfg.png'
          companies={150}
          link='/jobs?jobType[0]=Remote'
        />
        <FeaturedCard
          heading='Work Globally'
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1658213697/uploaded_resources/global_nkojj3.png'
          companies={70}
          link='/jobs?jobType[0]=Remote'
        />
      </div>
    </div>
  );
};

export default Index;
